import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { it, enGB, es } from "date-fns/locale";

export function DateRangePicker(props: any) {
  const { t, i18n } = useTranslation();

  const locale = useMemo(() => {
    if (i18n.language === "it") return it;
    if (i18n.language === "es") return es;
    return enGB;
  }, [i18n.language]);

  return (
    <div className={cn("grid gap-2", props.className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !props.value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {props.value?.from ? (
              props.value.to ? (
                <>
                  {format(props.value.from, "LLL dd, y", { locale: locale })} -{" "}
                  {format(props.value.to, "LLL dd, y", { locale: locale })}
                </>
              ) : (
                format(props.value.from, "LLL dd, y", { locale: locale })
              )
            ) : (
              <span>{props.placeholder ?? t("form.pick_date")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            style={{ pointerEvents: "all" }}
            mode="range"
            defaultMonth={props.value?.from}
            selected={props.value}
            onSelect={props.onChange}
            numberOfMonths={2}
            locale={locale}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
