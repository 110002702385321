// ModalContext.tsx
import ConfirmModal from "@/components/layout/ConfirmModal";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface ModalHeader {
  title: ReactNode | null;
  description: ReactNode | null;
  className?: string;
}
interface ModalContextProps {
  showModal: (content: ReactNode, header?: ModalHeader) => void;
  hideModal: () => void;
  confirmModal: (
    title: string,
    description: string,
    onConfirm: () => void
  ) => void;
  modalContent: ReactNode | null;
  isModalOpen: boolean;
  header: ModalHeader | null;
  className: string | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [header, setHeader] = useState<ModalHeader | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [className, setClassName] = useState<string | null>(null);

  const showModal = (content: ReactNode, header?: ModalHeader) => {
    setModalContent(content);
    setIsModalOpen(true);
    if (header) {
      setHeader({ ...header });
      setClassName(header.className || null);
    }
  };

  const confirmModal = (
    title: string,
    description: string,
    onConfirm: () => void
  ) => {
    showModal(<ConfirmModal onClose={hideModal} onConfirm={onConfirm} />, {
      title,
      description,
    });
  };

  const hideModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        confirmModal,
        modalContent,
        isModalOpen,
        header,
        className,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
