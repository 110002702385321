import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from "@/components/ui/pagination";
import UserGroupInvitationBanner from "@/components/auth/UserGroupInvitationBanner";
import UserCollectionInvitationBanner from "@/components/auth/UserCollectionInvitationBanner";
import GroupSection from "@/components/group/GroupSection";
import { Separator } from "@/components/ui/separator";
import { useDashboardData } from "@/hooks/dashboard";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NoGroup from "@/components/group/NoGroup";
import DashboardSkeleton from "./DashboardSkeleton";

export default function Dashboard() {
  const { t } = useTranslation();

  // SET PAGE TITLE
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  const {
    groups,
    collectionsGroupedByGroupId,
    groupInvitations,
    collectionInvitations,
    isLoading,
    page,
    totalPages,
    setPage,
    refresh,
  } = useDashboardData();

  const someInvitations =
    groupInvitations.length > 0 || collectionInvitations.length > 0;

  if (isLoading) return <DashboardSkeleton />;

  return (
    <>
      <div id="dashboard">
        <div className="space-y-8">
          {someInvitations && (
            <>
              <div className="space-y-4">
                {groupInvitations.map((group, index) => (
                  <UserGroupInvitationBanner
                    onRefresh={() => refresh()}
                    key={"groupInvitation" + index}
                    group={group}
                  />
                ))}

                {collectionInvitations.map((collection, index) => (
                  <UserCollectionInvitationBanner
                    onRefresh={() => refresh()}
                    key={"collectionInvitation" + index}
                    collection={collection}
                  />
                ))}
              </div>
              {groupInvitations.length > 0 && <Separator className="my-10" />}
            </>
          )}
          {groups.length > 0 && (
            <div className="space-y-20">
              {groups.map((group, i) => (
                <GroupSection
                  onRefresh={() => refresh()}
                  group={group}
                  collections={collectionsGroupedByGroupId[group._id]}
                  key={group._id}
                />
              ))}
            </div>
          )}
        </div>

        {groups.length > 5 ? (
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  href="#"
                  onClick={() => setPage(Math.max(page - 1, 1))}
                />
              </PaginationItem>

              {Array.from({ length: totalPages }, (_, i) => (
                <PaginationItem key={i}>
                  <PaginationLink
                    href="#"
                    isActive={page === i + 1}
                    onClick={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem>
                <PaginationNext
                  href="#"
                  onClick={() => setPage(Math.min(page + 1, totalPages))}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        ) : null}

        {/* Caso remoto in cui un utente si iscrive prima di ricevere un invito */}
        {groups.length === 0 && !someInvitations && <NoGroup />}
      </div>
    </>
  );
}
