import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../ui/button";
import { DialogClose } from "../ui/dialog";
import { DatePicker } from "../form_fields/DatePicker";
import { iCollection } from "@/services/collection";
import { useEffect, useMemo, useState } from "react";
import { Textarea } from "../ui/textarea";
import { LANG } from "@/services/i18next";

import { iNft } from "@/services/nft";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { H4 } from "../typography/Typography";
import {
  createFormExperienceSchema,
  FormSchemaExperience,
} from "./forms/validationSchema";

export default function FormExperience({
  collection,
  nft,
  showCloseBtn = false,
  submitBtnLabel,
  isSubmitting = false,
  onSubmit,
  owner,
}: {
  nft?: iNft;
  collection: iCollection;
  showCloseBtn?: boolean;
  submitBtnLabel?: string;
  isSubmitting?: boolean;
  onSubmit: (data: FormSchemaExperience) => void;
  owner?: string;
}) {
  const { t } = useTranslation();

  const formExperienceSchema = createFormExperienceSchema();

  const refinedSchema = formExperienceSchema.refine(
    (data) => {
      if (isEditMode) {
        return !!data.owner;
      }
      return true;
    },
    {
      message: "Owner is required in edit mode",
      path: ["owner"],
    }
  );

  const formDefaults = useMemo(() => {
    return {
      _createdAt: nft ? new Date(nft._createdAt) : undefined,
      owner: owner ? owner : nft ? nft.owner : "",
      lang: nft ? nft.lang : "",
      reference: nft?.payload?.reference || "",
      lockDate: nft?.lockDate ? new Date(nft.lockDate) : undefined,
      date: nft?.payload.date ? new Date(nft.payload.date) : undefined,
      extra: nft?.payload?.extra,
      originalPrice: nft?.originalPrice,
    };
  }, [nft]);

  const form = useForm<FormSchemaExperience>({
    resolver: zodResolver(refinedSchema),
    defaultValues: formDefaults,
  });

  //const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  // lockDate is one day before checkin, autofill it - TODO extract used in other forms
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === "start_date" && value.start_date) {
        const lockDate = new Date(value.start_date);
        lockDate.setDate(lockDate.getDate() - 1);
        form.setValue("lockDate", lockDate);
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  // if editMode load nft data
  useEffect(() => {
    if (nft) {
      // prefill formState with nft data
      form.reset(formDefaults);
      setIsEditMode(true);
    }
  }, [nft, form]);

  // list of languages
  const i18langs = useMemo(() => {
    return Object.keys(LANG).map((lang) => {
      return {
        text: lang,
        value: lang,
      };
    });
  }, [LANG]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="reference"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.reference")}*</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.reference")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="_createdAt" // secondo me meglio dedicare un altro campo...
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.booking_date")}</FormLabel>
                <FormControl>
                  <div>
                    <DatePicker className="w-full" {...field} />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.category")}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="amore">Amore</SelectItem>
                    <SelectItem value="a-caccia-del-bello">
                      A caccia del bello
                    </SelectItem>
                    <SelectItem value="adrenalina">Adrenalina</SelectItem>
                    <SelectItem value="artisti-artigiani">
                      Artisti e artigiani
                    </SelectItem>
                    <SelectItem value="benessere">Benessere</SelectItem>
                    <SelectItem value="come-fatto">Com’è fatto</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          {
            // during the creation the owner is handled by a different form - see FormCreateNftWrapper
            isEditMode && (
              <FormField
                control={form.control}
                name="owner"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t("nft.owner")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("nft.owner")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )
          }
          <FormField
            control={form.control}
            name="lang"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.lang")}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {i18langs.map((lang) => (
                      <SelectItem key={lang.value} value={lang.value}>
                        {lang.text}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="originalPrice"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.originalPrice")} (€)</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.originalPrice")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.price")} (€)</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.price")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
        </div>

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="start_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("exp.date")}</FormLabel>
                <FormControl>
                  <div>
                    <DatePicker className="w-full" {...field} />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lockDate" // secondo me meglio dedicare un altro campo...
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.lock_date")}</FormLabel>
                <FormControl>
                  <div>
                    <DatePicker className="w-full" {...field} />
                  </div>
                </FormControl>
                <FormDescription>
                  {t("nft.lock_date_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="extra"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("nft.extra")} {t("form.optional")}
              </FormLabel>
              <FormControl>
                <Textarea placeholder={t("nft.note")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <H4>{t("exp.details")}</H4>
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="partecipants"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.partecipants")}*</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.partecipants")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="duration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.duration")}*</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.duration")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex mt-10">
          {showCloseBtn && (
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                {t("buttons.close")}
              </Button>
            </DialogClose>
          )}
          <LoadingSubmitButton
            className="ml-auto"
            type="submit"
            variant={"takyon"}
            isLoading={isSubmitting}
          >
            {submitBtnLabel ?? t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
