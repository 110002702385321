import React, { createContext, useContext, useState } from "react";

interface TitleContextProps {
  title: string;
  setTitle: (title: string) => void;
  homeLink: string;
  setLogoHomeLink: (pathname: string) => void;
  isFree: boolean;
  setIsFree: (setIsFree: boolean) => void;
}

const TitleContext = createContext<TitleContextProps | undefined>(undefined);

export const TitleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [title, setTitle] = useState("");
  const [homeLink, setLogoHomeLink] = useState("");
  const [isFree, setIsFree] = useState(false);

  return (
    <TitleContext.Provider
      value={{ title, setTitle, homeLink, setLogoHomeLink, isFree, setIsFree }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export const useSectionTitle = () => {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error("useTitle must be used within a TitleProvider");
  }
  return context;
};
