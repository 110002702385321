import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenuItem,
} from "@/components/ui/sidebar";

import {
  BrainCircuit,
  CreditCard,
  FileChartColumn,
  HandCoins,
  Handshake,
  Languages,
  LayoutTemplate,
  LogOut,
  Mailbox,
  MessageCircleQuestion,
  ReceiptEuro,
  ScrollText,
  Users,
  Workflow,
} from "lucide-react";

import getLink, { LINK_TYPE } from "@/lib/links";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useModal } from "@/context/ModalContext";
import { useAuthContext } from "@/context/AuthContext";
import { useEffect, useState } from "react";
import { Separator } from "../ui/separator";

export default function AdminSidebar() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { userEmail, logout } = useAuthContext();
  const sidebarBaseUrl = "/";
  const [selectedPage, setSelectedPage] = useState("");

  useEffect(() => {
    let url = window.location.href.split("/");
    setSelectedPage(url[url.length - 1]);
  }, []);

  return (
    <Sidebar>
      <SidebarContent className="mt-16">
        <SidebarGroup>
          <SidebarGroupContent>
            {/* <SidebarMenuItem
                id="account"
                onClick={() => {
                  navigate(getLink(LINK_TYPE.ACCOUNT));
                  setSelectedPage("account");
                }}
                className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2 ${
                  selectedPage == "account"
                    ? "bg-green-500 text-white hover:bg-green-500"
                    : ""
                } `}
              >
                <User size={16} /> {t("nav.account")}
              </SidebarMenuItem>
              <SidebarMenuItem
                id="payment"
                onClick={() => {
                  navigate(getLink(LINK_TYPE.PAYMENT_METHODS));
                  setSelectedPage("payment");
                }}
                className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2 ${
                  selectedPage == "payment"
                    ? "bg-green-500 text-white hover:bg-green-500"
                    : ""
                } `}
              >
                <CreditCard size={16} />
                {t("nav.payment_methods")}
              </SidebarMenuItem>
              <SidebarMenuItem
                id="create"
                className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2`}
                onClick={() => {
                  showModal(
                    <CreateGroupForm
                      onSuccess={() => {
                        navigate(getLink(LINK_TYPE.HOME));
                        setSelectedPage("create");
                        window.location.reload();
                      }}
                    />,
                    {
                      title: t("group.create"),
                      description: "",
                    }
                  );
                }}
              >
                <Group size={16} />
                {t("nav.new_group")}
              </SidebarMenuItem> */}
            <SidebarMenuItem
              id="users"
              onClick={() => {
                navigate(getLink(LINK_TYPE.USERS));
                setSelectedPage("users");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "users" ? "underline" : ""
              }`}
            >
              <Users size={16} />
              {t("admin.menu.users")}
            </SidebarMenuItem>
            {/* <SidebarMenuItem
              id="invoices"
              onClick={() => {
                navigate(getLink(LINK_TYPE.INVOICES));
                setSelectedPage("invoices");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "invoices" ? "underline" : ""
              }`}
            >
              <ReceiptEuro size={16} />
              {t("admin.menu.invoices")}
            </SidebarMenuItem> */}
            <SidebarMenuItem
              id="partners"
              onClick={() => {
                navigate(getLink(LINK_TYPE.PARTNERS));
                setSelectedPage("partners");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "partners" ? "underline" : ""
              }`}
            >
              <Handshake size={16} />
              {t("admin.menu.partners")}
            </SidebarMenuItem>
            <SidebarMenuItem
              id="emails"
              onClick={() => {
                navigate(getLink(LINK_TYPE.EMAILS));
                setSelectedPage("emails");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "emails" ? "underline" : ""
              }`}
            >
              <Mailbox size={16} />
              {t("admin.menu.email_templates")}
            </SidebarMenuItem>
            <SidebarMenuItem
              id="support"
              onClick={() => {
                navigate(getLink(LINK_TYPE.SUPPORT));
                setSelectedPage("support");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "support" ? "underline" : ""
              }`}
            >
              <MessageCircleQuestion size={16} />
              {t("admin.menu.support_faqs")}
            </SidebarMenuItem>
            {/* <SidebarMenuItem
              id="promocodes"
              onClick={() => {
                navigate(getLink(LINK_TYPE.PROMOCODES));
                setSelectedPage("promocodes");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2 ${
                selectedPage == "promocodes"
                  ? "bg-green-500 text-white hover:bg-green-500"
                  : ""
              }`}
            >
              <HandCoins size={16} />
              {t("admin.menu.promo_codes")}
            </SidebarMenuItem> */}
            <SidebarMenuItem
              id="documents"
              onClick={() => {
                navigate(getLink(LINK_TYPE.DOCUMENTS));
                setSelectedPage("documents");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "documents" ? "underline" : ""
              }`}
            >
              <ScrollText size={16} />
              {t("admin.menu.documents")}
            </SidebarMenuItem>
            <SidebarMenuItem
              id="translations"
              onClick={() => {
                navigate(getLink(LINK_TYPE.TRANSLATIONS));
                setSelectedPage("translations");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "translations" ? "underline" : ""
              }`}
            >
              <Languages size={16} />
              {t("admin.menu.translations")}
            </SidebarMenuItem>
            {/* <SidebarMenuItem
              id="nfts"
              onClick={() => {
                navigate(getLink(LINK_TYPE.NFTS));
                setSelectedPage("nfts");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "nfts" ? "underline" : ""
              }`}
            >
              <CreditCard size={16} />
              {t("admin.menu.nfts")}
            </SidebarMenuItem> */}
            {/* <SidebarMenuItem
              id="ai"
              onClick={() => {
                navigate(getLink(LINK_TYPE.AI));
                setSelectedPage("ai");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2 ${
                selectedPage == "ai"
                  ? "bg-green-500 text-white hover:bg-green-500"
                  : ""
              }`}
            >
              <BrainCircuit size={16} />
              {t("admin.menu.ai_gennaro")}
            </SidebarMenuItem> */}
            {/* <SidebarMenuItem
              id="reports"
              onClick={() => {
                navigate(getLink(LINK_TYPE.REPORTS));
                setSelectedPage("reports");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:outline hover:bg-gray-200 p-2 ${
                selectedPage == "reports"
                  ? "bg-green-500 text-white hover:bg-green-500"
                  : ""
              }`}
            >
              <FileChartColumn size={16} />
              {t("admin.menu.reports")}
            </SidebarMenuItem>
 */}
            <SidebarMenuItem
              id="hotel-integration"
              onClick={() => {
                navigate(getLink(LINK_TYPE.HOTEL_REGISTRATION_MANAGER));
                setSelectedPage("hotel-integration");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "translations" ? "underline" : ""
              }`}
            >
              <Workflow size={16} />
              {t("admin.menu.hotelIntegrations")}
            </SidebarMenuItem>
            <SidebarMenuItem
              id="dataanalysismask"
              onClick={() => {
                navigate(getLink(LINK_TYPE.DATA_ANALYSIS_MASKS));
                setSelectedPage("data-analysis-mask");
              }}
              className={`cursor-pointer flex gap-2 rounded-md outline-1 hover:bg-gray-100 hover:underline p-2 ${
                selectedPage == "translations" ? "underline" : ""
              }`}
            >
              <LayoutTemplate size={16} />
              {t("admin.menu.data-analysis-masks")}
            </SidebarMenuItem>
            <SidebarMenuItem>
              <button
                className="hover:bg-gray-100 hover:underline ml-2 mt-2"
                onClick={() => {
                  throw new Error("Test Sentry!");
                }}
              >
                Sentry Test
              </button>
            </SidebarMenuItem>
            <Separator className="my-4" />
            <SidebarMenuItem
              className={`cursor-pointer flex gap-2 `}
              onClick={() => logout()}
            >
              <LogOut size={16} />
              {t("nav.logout")}
            </SidebarMenuItem>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>{userEmail}</SidebarFooter>
    </Sidebar>
  );
}
