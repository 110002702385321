import React from "react";
import { Separator } from "@/components/ui/separator";
import { Outlet } from "react-router-dom";
import Header from "@/components/layout/Header";
import Footer from "./Footer";

const Layout: React.FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header />
      <Separator />
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
