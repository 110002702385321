import AskUpgrateToPro from "@/components/actions/AskUpgrateToPro";
import { useAuthContext } from "@/context/AuthContext";
import { CircleCheckBig, Minus } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

interface Feature {
  name: string;
  tooltip?: string;
  free: string | boolean;
  pro: any;
}

export default function GoToProModal() {
  const { t } = useTranslation();

  const features: Feature[] = [
    { name: "pro_modal.sellable_tak_generation", free: true, pro: true },
    {
      name: "pro_modal.booking_engine_integration",
      free: false,
      pro: true,
      tooltip: "pro_modal.booking_engine_integration_tooltip",
    },
    {
      name: "pro_modal.guaranteed_resale",
      free: false,
      pro: true,
      tooltip: "pro_modal.guaranteed_resale_tooltip",
    },
    {
      name: "pro_modal.pro_analytics",
      free: "pro_modal.pro_analytics_free",
      pro: true,
      tooltip: "pro_modal.pro_analytics_tooltip",
    },
    {
      name: "pro_modal.wallet_pass_generation",
      free: true,
      pro: true,
      tooltip: "pro_modal.wallet_pass_generation_tooltip",
    },
    {
      name: "pro_modal.seo_site_audit",
      free: true,
      pro: true,
      tooltip: "pro_modal.seo_site_audit_tooltip",
    },
    {
      name: "pro_modal.assistance",
      free: "pro_modal.assistance_free",
      pro: "pro_modal.assistance_pro",
      tooltip: "pro_modal.assistance_tooltip",
    },
    {
      name: "pro_modal.access_new_features",
      free: false,
      pro: true,
      tooltip: "pro_modal.access_new_features_tooltip",
    },
    {
      name: "pro_modal.priority_direct_search_visibility",
      free: "pro_modal.priority_direct_search_visibility_free",
      pro: "pro_modal.priority_direct_search_visibility_pro",
      tooltip: "pro_modal.priority_direct_search_visibility_tooltip",
    },
  ];

  const { userEmail } = useAuthContext();

  const renderValue = (value: string | boolean) => {
    if (typeof value === "boolean") {
      if (value) {
        return <CircleCheckBig size={20} className="w-5 h-5" />;
      }
      return <Minus size={20} className="w-5 h-5" />;
    }
    if (typeof value === "string") {
      return <span>{t(value)}</span>;
    }
  };

  return (
    <div className="rounded border overflow-hidden">
      <div className="grid grid-cols-3">
        {/* Headers */}
        <div className="h-36 flex flex-col items-center justify-center font-semibold border-r" />
        <div className="h-36 flex flex-col items-center justify-center p-3 space-y-1">
          <h3 className="text-xl font-semibold">Free</h3>
          <span className="text-xs text-gray-400">
            {t("pro_modal.now_active")}
          </span>
        </div>
        <div className="h-36 flex flex-col items-center justify-center p-3 space-y-2 bg-black text-white">
          <h3 className="text-xl font-bold text-gradient">Takyon Pro</h3>
          <span className="text-sm mb-2">€150/{t("pro_modal.monthly")}</span>
          <AskUpgrateToPro />
        </div>

        {/* Features Grid */}
        <div>
          {features.map((feature, index) => (
            <div
              key={index}
              className="h-[48px] px-6 flex items-center border-t border-r text-sm select-none"
            >
              {feature.tooltip ? (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span className="underline">{t(feature.name)}</span>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="w-96">
                      <p>{t(feature.tooltip)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <span>{t(feature.name)}</span>
              )}
            </div>
          ))}
        </div>
        <div>
          {features.map((feature, index) => (
            <div
              key={index}
              className="h-[48px] px-6 flex items-center justify-center border-t text-sm"
            >
              {renderValue(feature.free)}
            </div>
          ))}
        </div>
        <div className="bg-black text-white">
          {features.map((feature, index) => (
            <div
              key={index}
              className="h-[48px] px-6 flex items-center justify-center border-t border-gray-800 text-sm"
            >
              {renderValue(feature.pro)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
