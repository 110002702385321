import { Button } from "@/components/ui/button";
import { TFunction } from "i18next";
import { Eye, LucideEdit, LucideEye } from "lucide-react";
import { iNft, NFT_OWNER_TYPE, NFT_SELL_STATUS } from "@/services/nft";
import { datetimeToString } from "../../../utils/generic";
import CopyId from "@/components/helpers/CopyId";
import { ColumnDef } from "@tanstack/react-table";
import { useModal } from "@/context/ModalContext";
import DetailHotel from "@/components/collection/DetailHotel";
import FormEditNftWrapper from "@/components/collection/FormEditNftWrapper";

export const getColumns = (
  t: TFunction,
  onRefresh: Function
): ColumnDef<iNft>[] => {
  const { showModal } = useModal();
  return [
    {
      header: "ID",
      accessorKey: "_id",
      cell: ({ getValue }) => (
        <div className="flex gap-1 items-center">
          <CopyId resourceId={getValue() as string} />
          <Eye
            className="cursor-pointer"
            size={16}
            onClick={() => {
              // showModal(<NftModal nftId={id} />, {
              //   title: " ",
              //   description: " ",
              // });
            }}
          ></Eye>
        </div>
      ),
    },
    {
      id: "creationDate",
      header: t("nft.taks_creation_date"),
      accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
    },
    {
      header: "Booking Reference",
      accessorKey: "payload.reference",
    },
    {
      header: "Hotel",
      accessorKey: "_collectionCensus.name",
      // accessorFn: (row: any) => new Date(row._createdAt).toLocaleDateString(),
    },
    {
      header: "Owner Email",
      accessorKey: "owner",
      // accessorFn: (row: any) =>
      //   `${new Date(row.expiresAt) > new Date() ? "🟢" : "🔴"}
      // ${new Date(row.expiresAt).toLocaleDateString()}`,
      cell: function render(props: any) {
        let ownerEmail = props.getValue();
        let ownerType = props.row.original.ownerType;
        return (
          <p
            style={{
              whiteSpace: "nowrap",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {ownerType === NFT_OWNER_TYPE.RESOLD ? "🟡" : ""}{" "}
            <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>
          </p>
        );
      },
    },
    {
      header: "Guests",
      accessorKey: "",
      // accessorFn: (row: any) => new Date(row._createdAt).toLocaleDateString(),
      cell: function render(props: any) {
        return (
          <p>
            {props.row.original.payload?.rooms?.reduce(
              (total: any, room: any) => {
                return (
                  total + (room.guestsAdults ?? 0) + (room.guestsKids ?? 0)
                );
              },
              0
            )}
          </p>
        );
      },
    },
    {
      header: "Check-in",
      accessorFn: (nft) => {
        const checkin = datetimeToString(
          nft.payload?.checkin,
          nft.payload?.location?.gmt,
          true
        );
        return `${checkin}`;
      },
    },
    {
      header: "Check-out",
      accessorFn: (nft) => {
        const checkout = datetimeToString(
          nft.payload?.checkout,
          nft.payload?.location?.gmt,
          true
        );
        return `${checkout}`;
      },
    },
    {
      header: "Purchase price",
      accessorKey: "original_price",
      // accessorFn: (value: any) => {
      //   console.log("value : ", value);
      //   return `${Number(nft.originalPrice).toFixed(2)}€`;
      // },
      cell: function render(props: any) {
        return (
          <p>
            {props.row.original.originalPrice
              ? `${Number(props.row.original.originalPrice).toFixed(2)} €`
              : ""}
          </p>
        );
      },
    },
    {
      header: "Resell price",
      accessorKey: "current_price",
      // accessorFn: (row: any) => new Date(row._createdAt).toLocaleDateString(),
      cell: function render(props: any) {
        return (
          <p>
            {props.row.original.currentPrice &&
              `${Number(props.row.original.currentPrice).toFixed(2)} €`}
          </p>
        );
      },
    },
    {
      header: "For sale",
      accessorKey: "sale",
      // accessorFn: (row: any) => new Date(row._createdAt).toLocaleDateString(),
      cell: function render(props: any) {
        return (
          <p>
            {props.row.original.sellStatus === NFT_SELL_STATUS.FOR_SALE && "🟢"}
          </p>
        );
      },
    },
    {
      id: "actions",
      accessorKey: "id",
      header: t("nft.taks_actions"),
      cell: function render({ getValue, row }) {
        const { showModal, hideModal } = useModal();

        return (
          <div className="flex flex-row flex-nowrap gap-0">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(
                  <DetailHotel
                    nft={row.original}
                    collectionCensus={row.original._collectionCensus}
                  />,
                  {
                    title: (
                      <>
                        {t("nft.modal_detail_title")} - #{row.original.shortId}
                        <CopyId resourceId={row.original._id!} />
                      </>
                    ),
                    description: null,
                  }
                );
              }}
            >
              <LucideEye size={16} />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(
                  <FormEditNftWrapper
                    nftId={row.original._id!}
                    collection={row.original._collection!}
                    onSuccess={() =>
                      console.log("FormEditNftWrapper onSuccess")
                    }
                  />,
                  {
                    title: (
                      <>
                        {t("nft.update")}{" "}
                        <CopyId resourceId={row.original._id!} />
                      </>
                    ),
                    description: null,
                  }
                );
              }}
            >
              <LucideEdit size={16} />
            </Button>
          </div>
        );
      },
    },
    // {
    //   header: "Auth link",
    //   accessorKey: "",
    //   cell: function render(props: any) {
    //     return (
    //       <a
    //         target="_blank"
    //         rel="noreferrer"
    //         href={`https://takyon.io/?promocode=${props.row.original.code}`}
    //       >{`takyon.io/?promocode=${props.row.original.code}`}</a>
    //     );
    //   },
    // },
  ];
};
