import { H4 } from "../typography/Typography";
import { Label } from "@/components/ui/label";
import { iNft, NFT_OWNER_TYPE, NFT_SELL_STATUS } from "@/services/nft";
import { datetimeToString } from "@/utils/generic";
import { useTranslation } from "react-i18next";
import { DL, DLItem } from "../dataview/dl";
import { Button } from "../ui/button";
import { SquareArrowOutUpRight } from "lucide-react";
import { useMemo } from "react";

interface NftDetailProps {
  nft: iNft; // if passed like prop
  collectionCensus: any;
}

interface OwnerDetails {
  firstName: string | undefined;
  secondName: string | undefined;
  phonePrefix: string | undefined;
  phone: string | undefined;
}

export default function DetailHotel({ nft, collectionCensus }: NftDetailProps) {
  const { t } = useTranslation();

  const takyonUrl = `${import.meta.env.VITE_FRONTEND_URI}/tak/${nft!._id}`;

  const ownerDetails: OwnerDetails | undefined = useMemo(() => {
    if (nft!._owner) {
      return {
        firstName: nft!._owner.firstName,
        secondName: nft!._owner.secondName,
        phonePrefix: nft!._owner.phonePrefix,
        phone: nft!._owner.phone,
      };
    } else if (nft!.guests?.[0]) {
      return {
        firstName: nft!.guests[0].firstName,
        secondName: nft!.guests[0].secondName,
        phonePrefix: undefined,
        phone: undefined,
      };
    } else {
      return undefined;
    }
  }, [nft]);

  return (
    <div className="space-y-4 divide-y divide-gray-200 pe-4">
      <div className="pt-3">
        <DL>
          <DLItem title={t("nft.owner_type")}>
            {nft!.ownerType === NFT_OWNER_TYPE.ORIGINAL
              ? "⚪ " + t("nft.owner_original")
              : "🟡 " + t("nft.owner_resold")}
          </DLItem>
          <DLItem title={t("nft.status")}>
            {nft!.sellStatus === NFT_SELL_STATUS.FOR_SALE
              ? "🟢 " + t("nft.for_sale")
              : "⚪ " + t("nft.not_for_sale")}
          </DLItem>
          <DLItem title={t("nft.details_created_at")}>
            {datetimeToString(
              nft!._createdAt,
              nft!.payload.location?.gmt,
              true,
              false
            )}
          </DLItem>
          <DLItem title={t("nft.view_exchange")}>
            <Button
              variant="ghost"
              onClick={() => window.open(takyonUrl, "_blank")}
            >
              <SquareArrowOutUpRight size={16} />
            </Button>
          </DLItem>
        </DL>
      </div>
      {ownerDetails && (
        <div className="pt-3">
          <DL title={t("nft.details_headedto")}>
            {ownerDetails.firstName && (
              <DLItem title={t("nft.owner_name")}>
                {ownerDetails.firstName}
              </DLItem>
            )}
            {ownerDetails.secondName && (
              <DLItem title={t("nft.owner_second_name")}>
                {ownerDetails.secondName}
              </DLItem>
            )}
            {nft!._owner && (
              <DLItem title={t("nft.owner_email")}>
                <a href={`mailto:${nft!.owner}`}>{nft!.owner}</a>
              </DLItem>
            )}
            {ownerDetails.phone && (
              <DLItem title={t("nft.owner_phone")}>
                {ownerDetails.phonePrefix} {ownerDetails.phone}
              </DLItem>
            )}
          </DL>
        </div>
      )}
      <div className="pt-3">
        <DL title={t("nft.details_date")}>
          <DLItem title={t("nft.checkin")}>
            {datetimeToString(
              nft!.payload?.checkin,
              nft!.payload?.location?.gmt
            )}
          </DLItem>
          <DLItem title={t("nft.checkout")}>
            {datetimeToString(
              nft!.payload?.checkout,
              nft!.payload?.location?.gmt
            )}
          </DLItem>
          <DLItem title={t("nft.lock_date")}>
            {datetimeToString(
              nft!.lockDate,
              nft!.payload?.location?.gmt,
              false,
              true
            )}
          </DLItem>
        </DL>
      </div>
      <div className="pt-3">
        <DL title={t("nft.details_location")}>
          <DLItem title={t("nft.hotel")}>
            {collectionCensus?.name} ({collectionCensus?.stars} {t("nft.stars")}
            )
          </DLItem>
          <DLItem title={t("nft.website")}>
            <a
              href={collectionCensus?.website}
              target="_blank"
              rel="noreferrer"
            >
              {collectionCensus?.website}
            </a>
          </DLItem>
          <DLItem title={t("nft.location")}>
            {collectionCensus?.location?.label}
          </DLItem>
        </DL>
      </div>
      <div className="pt-3">
        <DL title={t("nft.details_rooms")}>
          {nft.payload?.board && (
            <DLItem title={t("nft.board")}>{nft.payload?.board}</DLItem>
          )}
          {nft.payload?.extra ? (
            <DLItem title={t("nft.extra")}>{nft.payload?.extra}</DLItem>
          ) : null}
          <div className="space-y-3 divide-y divide-gray-200">
            {nft!.payload?.rooms?.map((room: any, key: number) => {
              return (
                <div key={"room_" + key}>
                  <DLItem title={t("nft.rooms")}>{room.name}</DLItem>
                  <DLItem title={t("nft.guest_adults")}>
                    {room.guestsAdults ?? 0}
                  </DLItem>
                  <DLItem title={t("nft.guest_kids")}>
                    {room.guestsKids ?? 0}
                  </DLItem>
                  {room.amenities && (
                    <DLItem title={t("nft.amenities")}>{room.amenities}</DLItem>
                  )}
                </div>
              );
            })}
          </div>
        </DL>
      </div>
      <div className="pt-3">
        <DL title={t("nft.details_extra")}>
          <DLItem title={t("nft.tak_no")}>{nft!.shortId}</DLItem>
          <DLItem title={t("nft.id")}>{nft!._id}</DLItem>
        </DL>
      </div>
    </div>
  );
}
