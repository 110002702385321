import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Repeat } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function BuybackBanner({ nftsForSale }: { nftsForSale: any[] }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buybacksCount = nftsForSale.length; // getBuybacksCount();data.nftsForSale?.length

  return (
    <Card className="w-full rounded-md bg-black p-5 flex justify-between items-center">
      <div>
        <p className="font-medium text-xl" style={{ color: "#43F3AE" }}>
          {t("collection.analytics.generatedtak.buyback_title", {
            count: buybacksCount,
          })}
        </p>
        <p className="font-medium text-sm text-white">
          {t("collection.analytics.generatedtak.buyback_subtitle")}
        </p>
      </div>
      <div>
        <Button
          variant={"outline2"}
          onClick={() => {
            navigate(`buybacks`);
          }}
        >
          <Repeat className="mr-2" />
          {t("collection.analytics.generatedtak.buyback_button")}
        </Button>
      </div>
    </Card>
  );
}
