import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import FormDeveloperApiKey from "./FormDeveloperApiKey";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { deleteApiKey, getMyApiKeys, iApiKey } from "@/services/apikey";
import { LucideTrash2 } from "lucide-react";
import DeleteModal from "@/components/ui/deleteModal";

export default function DeveloperApiKeys({}: {}) {
  const { showModal, hideModal } = useModal();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKeys();
  }, []);

  // const instructionLink = useMemo(() => {
  //   console.log("currentLanguage", currentLanguage);
  //   if (currentLanguage == "IT") {
  //     return "https://drive.google.com/uc?export=download&id=1Gvcd0q0HxGKh8pxwuQGVs3hrrHPME_FO";
  //   } else {
  //     return "https://drive.google.com/uc?export=download&id=1R5_seR8VDDux7njyDKJLhc9iSACyRCFr";
  //   }
  // }, [t]);

  const onRemoveApiKey = async (apiKey: any) => {
    try {
      // const data = formToObject(e.target);
      await deleteApiKey({
        apikeyId: apiKey._id,
      });
      hideModal();
    } catch (error) {
      console.log(error);
    }

    await loadApiKeys();
  };

  return (
    <>
      <p className="text-xl font-bold title-section">{t("dev.trak_title")}</p>
      <p className="mt-2">{t("dev.trak_desc1")}</p>
      <div className="w-full flex justify-center">
        <pre className="my-9 bg-gray-100 p-4 rounded-lg w-fit">
          <code className="text-sm font-mono">
            {"<"}
            <span className="text-amber-800">script</span>{" "}
            <span className="text-purple-600">defer</span>{" "}
            <span className="text-purple-600">src</span>
            {'="'}
            <span className="text-amber-800">
              https://api.takyon.io/scripts/tracking
            </span>
            {'"'}
            {"</"}
            <span className="text-amber-800">script</span>
            {">"}
          </code>
        </pre>
      </div>
      <div className="mt-4">
        <p
          className=""
          dangerouslySetInnerHTML={{ __html: t("dev.trak_desc2") }}
        />
      </div>
      <div className="mt-4 mb-11 flex flex-col space-y-3">
        <p dangerouslySetInnerHTML={{ __html: t("dev.trak_desc3") }}></p>
        <p className="mt-1">
          <a
            target="_blank"
            href="https://drive.google.com/uc?export=download&id=1Gvcd0q0HxGKh8pxwuQGVs3hrrHPME_FO"
            className="underline"
          >
            📄 Istruzioni per l’installazione del Sistema di Tracciamento Takyon
          </a>
        </p>
        <p>
          <a
            target="_blank"
            href="https://drive.google.com/uc?export=download&id=1R5_seR8VDDux7njyDKJLhc9iSACyRCFr"
            className="underline"
          >
            📄 Takyon Tracking System Installation Instructions
          </a>
        </p>
      </div>
      <div className="flex w-full justify-between items-center border-t pt-4">
        <span className="text-xl font-bold title-section">
          {t("dev.api_key_list")}
        </span>
        <Button
          className="ml-auto"
          // loading={isLoading}
          variant="takyon"
          onClick={() =>
            showModal(<FormDeveloperApiKey />, {
              title: t("dev.create_apikey"),
              description: "",
            })
          }
          // text={String(t("dev.create_apikey"))}
        >
          {t("dev.create_apikey")}
        </Button>
      </div>
      <div className="apikey-list">
        {apiKeys.map((apikey, key) => {
          return (
            <div
              className="apikey mt-5 mb-8 p-6 border rounded-md flex justify-between"
              key={key}
            >
              <div>
                <p className="font-semibold mb-2">{apikey.name}</p>
                <span>{apikey.token}</span>
              </div>
              <LucideTrash2
                size={16}
                onClick={() =>
                  showModal(
                    <DeleteModal onRemove={() => onRemoveApiKey(apikey)} />,
                    {
                      title: t("modals.remove_modal"),
                      description: t("modals.remove_modal_text"),
                    }
                  )
                }
                className="apikey-delete"
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
