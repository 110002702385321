import { COLLECTION_TYPE, iCollection } from "@/services/collection";
import FormExperience from "./FormExperience";
import FormNft from "./FormNft";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  getNft,
  iNft,
  patchNft,
  payloadExperienceType,
  payloadHotelType,
} from "@/services/nft";
import { LANG } from "@/services/i18next";
import { useFeedback } from "@/context/FeedbackContext";
import {
  FormSchemaExperience,
  FormSchemaHotel,
} from "./forms/validationSchema";
import { prepareLockDate } from "@/utils/nft";

export default function FormEditNftWrapper({
  nftId,
  collection,
  onSuccess,
}: {
  nftId: string;
  collection: iCollection;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showFeedback } = useFeedback();

  // LOAD CURRENT NFT DATA
  useEffect(() => {
    if (nftId) {
      const loadNft = async (nftId: string) => {
        // setIsLoading(true);
        try {
          const currentNft = await getNft(nftId);
          setNft(currentNft);
        } catch (error) {
          console.log(error);
        }
        // setIsLoading(false);
      };

      loadNft(nftId);
    }
  }, [nftId]);

  const handleFormExperienceSubmit = async (data: FormSchemaExperience) => {
    console.log("handleFormExperienceSubmit onSubmit >>>> data", data);

    if (!collection) {
      return;
    }

    setIsSubmitting(true);

    // map form field to iNft
    const nft: iNft<payloadExperienceType> = {
      collectionId: collection._id,
      _createdAt: data._createdAt,
      owner: data.owner!,
      lang: data.lang as LANG,
      originalPrice: data.originalPrice,
      // price: data.price,
      lockDate: prepareLockDate(
        data.lockDate,
        collection.census?.location?.gmt
      ),
      payload: {
        reference: data.reference,
        date: data.start_date.toISOString(), // TODO - will be moved to nft root?
        participants: Number(data.partecipants),
        duration: Number(data.duration),
        type: data.type,
        category: data.category,
        extra: data.extra,
      },
      fakeOwnerName: data.fakeOwnerName || "",
    };

    try {
      await patchNft(nft);
      showFeedback({
        title: t("nft.update_feedback_title"),
        message: t("nft.update_feedback_message"),
        onClose: onSuccess,
      });
    } catch (error: Error | any) {
      // TODO  - improve error handlin and translations
      showFeedback({
        title: t("error.tryagain"),
        message: error.response.data.message,
      });
    }

    setIsSubmitting(false);
  };

  const handleFormNftSubmit = async (data: FormSchemaHotel) => {
    console.log("handleFormNftSubmit - onSubmit >>>> data", data);

    if (!collection) {
      showFeedback({
        title: t("error.error"),
        message: t("error.collection_not_found"),
      });
      return;
    }

    setIsSubmitting(true);

    // map form field to iNft
    const nft: iNft<payloadHotelType> = {
      collectionId: collection._id,
      _createdAt: data._createdAt,
      owner: data.owner!,
      lang: data.lang as LANG,
      originalPrice: data.price,
      lockDate: prepareLockDate(
        data.lockDate,
        collection.census?.location?.gmt
      ),
      images: data.images,
      payload: {
        reference: data.reference,
        checkin: data.checkin.from.toISOString(), // old version: dateToGMT(e,collection.census?.location?.gmt).toISOString();
        checkout: data.checkin.to.toISOString(),
        board: data.board,
        rooms: data.rooms.map((room) => ({
          name: room.name,
          guestsAdults: Number(room.guestsAdults),
          guestsKids: Number(room.guestsKids),
        })),
        extra: data.extra,
      },
      guests: [
        {
          firstName: data.firstName,
          secondName: data.lastName,
          email: data.owner,
        },
      ],
      fakeOwnerName: data.fakeOwnerName || "",
    };

    try {
      await patchNft(nft);
      showFeedback({
        title: t("nft.update_feedback_title"),
        message: t("nft.update_feedback_message"),
        onClose: onSuccess,
      });
    } catch (error: Error | any) {
      // TODO  - improve error handlin and translations
      showFeedback({
        title: "An error occurred. Please try again.",
        message: error.response.data.message,
      });
    }

    setIsSubmitting(false);
  };

  const [nft, setNft] = useState<iNft>();

  return (
    <>
      {collection.type === COLLECTION_TYPE.experience ? (
        <FormExperience
          collection={collection}
          onSubmit={handleFormExperienceSubmit}
          isSubmitting={isSubmitting}
          nft={nft}
        />
      ) : (
        <FormNft
          collection={collection}
          nft={nft}
          isSubmitting={isSubmitting}
          onSubmit={handleFormNftSubmit}
        />
      )}
    </>
  );
}
