import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { useAuthContext } from "@/context/AuthContext";

export default function AskUpgrateToPro() {
  const { t } = useTranslation();
  const { userEmail } = useAuthContext();

  const onClick = () => {
    const subject = encodeURIComponent(
      t("mailto.send_upgrade_pro.subject", {
        userEmail: userEmail,
      })
    );
    const body = encodeURIComponent(t("mailto.send_upgrade_pro.body"));

    window.location.href = `mailto:sales@takyon.io?subject=${subject}&body=${body}`;
  };

  return (
    <Button variant="gradient" onClick={onClick}>
      {t("actions.send_upgrade_pro")}
    </Button>
  );
}
