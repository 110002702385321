import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { iGroup, patchGroup } from "@/services/groups";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { useAuthContext } from "@/context/AuthContext";
import { useRbac } from "@/hooks/rbac";
import { TFunction } from "i18next";

const createFormSchema = (t: TFunction) => {
  return z.object({
    name: z.string(),
    feeOnCreation: z.number().optional(),
    feeMonthly: z.number().optional(),
    feeFixedOnCreation: z.number().optional(),
    creatorFee: z.number().optional(),
  });
};

export function GroupSettingsFormAdmin({
  group,
  onSuccess,
}: {
  group: iGroup;
  onSuccess(): void;
}) {
  const user = useSelector(selectUser);
  const { isSuperAdmin } = useRbac();

  const { t } = useTranslation();

  const formSchema = createFormSchema(t);

  // Initialize the form with appropriate schema and default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: group.name,
      feeOnCreation: group.feeOnCreation || 0,
      feeMonthly: group.feeMonthly || 0,
      feeFixedOnCreation: group.feeFixedOnCreation || 0,
      creatorFee: group.creatorFee || 0,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      // Create the payload based on user role
      const payloadBase = {
        groupId: group._id,
        name: values.name,
      };

      const payloadAdmin = isSuperAdmin
        ? {
            feeOnCreation: values.feeOnCreation,
            feeMonthly: values.feeMonthly,
            feeFixedOnCreation: values.feeFixedOnCreation,
            creatorFee: values.creatorFee,
          }
        : {};

      // Submit the form with the correct payload
      await patchGroup({ ...payloadBase, ...payloadAdmin });

      onSuccess();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          {/* Name field (shown to all users) */}
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("group.name")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("group.name")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="feeOnCreation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("group.feeOnCreation")}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t("group.feeOnCreation")}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="feeMonthly"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("group.feeMonthly")}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t("group.feeMonthly")}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="feeFixedOnCreation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("group.feeFixedOnCreation")}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t("group.feeFixedOnCreation")}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="creatorFee"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("group.creatorFee")}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t("group.creatorFee")}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div>
            <Button variant={"takyon"} disabled={isLoading} type="submit">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
