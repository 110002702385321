import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import { nftResponse } from "@/services/dataanalysismask";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Repeat, Users } from "lucide-react";
import { iNft } from "@/services/nft";
import {
  datetimeToString,
  formatNumberEuropean,
  getAmountOfNights,
} from "@/utils/generic";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useRbac } from "@/hooks/rbac";

export default function GeneratedTak({ data }: { data: nftResponse }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isSuperAdmin } = useRbac();

  const takyonColor = "#43F3AE";
  const otherColor = "#E8E8E8";

  const chartConfig = {
    takyon: {
      label: "Takyon",
      color: takyonColor,
    },
    other: {
      label: t("collection.analytics.generatedtak_others"),
      color: otherColor,
    },
  } satisfies ChartConfig;
  const getMonthString = (monthNumber: number) => {
    const monthNames = [
      "",
      t("date.january"),
      t("date.february"),
      t("date.march"),
      t("date.april"),
      t("date.may"),
      t("date.june"),
      t("date.july"),
      t("date.august"),
      t("date.september"),
      t("date.october"),
      t("date.november"),
      t("date.december"),
    ];
    return monthNames[monthNumber];
  };

  const chartBookingsAmount =
    data.bookingsAmount?.map((item) => ({
      ...item,
      month: getMonthString(item.month + 1),
    })) || [];

  const getNPeople = (nft: iNft): number => {
    const guestsAdults = nft?.payload?.rooms[0]?.guestsAdults || 0;
    const guestsKids = nft?.payload?.rooms[0]?.guestsKids || 0;
    return guestsAdults + guestsKids;
  };

  return (
    <Card className="rounded-xl w-full p-5">
      <CardTitle className="text-2xl font-medium">
        {t("collection.analytics.generatedtak_title")}
      </CardTitle>
      <CardContent className="flex flex-col gap-4 mt-5 p-0">
        <div className="flex w-full gap-4 ">
          {isSuperAdmin && (
            <Card className="rounded-md w-1/3">
              <CardContent className="p-5 py-3">
                <p className="text-xl font-medium">
                  {t("collection.analytics.generatedtak_chart_title")}
                </p>
                <ChartContainer
                  className="w-full mt-14 max-w-full generatedTakChart"
                  style={{ minHeight: "240px" }}
                  config={chartConfig}
                >
                  <BarChart
                    accessibilityLayer
                    data={chartBookingsAmount}
                    barSize={16}
                    reverseStackOrder={true}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      hide={false}
                      dataKey="month"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                      tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <ChartTooltip
                      content={<ChartTooltipContent className="chartTooltip" />}
                    />
                    <ChartLegend
                      content={
                        <ChartLegendContent className="flex-row-reverse" />
                      }
                    />
                    {/* 
                    dataKey takyon corrispond to n. of taks in the database
                    <Bar
                      dataKey="takyon"
                      stackId="a"
                      fill={takyonColor}
                      radius={[4, 4, 0, 0]}
                    /> */}
                    <Bar
                      dataKey="takscript"
                      stackId="a"
                      fill={takyonColor}
                      radius={[4, 4, 0, 0]}
                    />
                    <Bar
                      dataKey="other"
                      stackId="a"
                      fill={otherColor}
                      radius={[0, 0, 4, 4]}
                    />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          )}
          <Card
            className={isSuperAdmin ? "rounded-md w-2/3" : "rounded-md w-full"}
          >
            <CardContent className="p-5 py-3">
              <p className="text-xl font-medium">
                {t("collection.analytics.generatedtak_table_title")}
              </p>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("analytics.generatedtak.header1")}</TableHead>
                    <TableHead className="text-center">
                      {t("analytics.generatedtak.headerCreatedData")}
                    </TableHead>
                    <TableHead className="text-center">
                      {t("analytics.generatedtak.header2")}
                    </TableHead>
                    <TableHead className="text-center">
                      {t("analytics.generatedtak.header3")}
                    </TableHead>
                    <TableHead className="text-center">
                      {t("analytics.generatedtak.header4")}
                    </TableHead>
                    <TableHead className="text-right">
                      {t("analytics.generatedtak.header5")}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.nfts?.slice(0, 4).map((nft: iNft, index: number) => (
                    <TableRow key={index}>
                      <TableCell className="flex gap-2 items-center">
                        <Users size={16}></Users>
                        <p>{getNPeople(nft)}</p>
                      </TableCell>
                      <TableCell className="text-center">
                        {nft._createdAt
                          ? datetimeToString(
                              new Date(nft._createdAt),
                              nft.payload?.location?.gmt,
                              true
                            )
                          : ""}
                      </TableCell>
                      <TableCell className="text-center">
                        {nft.payload.checkin
                          ? datetimeToString(
                              new Date(nft.payload.checkin),
                              nft.payload?.location?.gmt,
                              true
                            )
                          : ""}
                      </TableCell>
                      <TableCell className="text-center">
                        {getAmountOfNights(
                          nft.payload.checkin,
                          nft.payload.checkout
                        )}
                      </TableCell>
                      <TableCell className="text-center">
                        {getAmountOfNights(nft._createdAt, nft.payload.checkin)}{" "}
                        {t("analytics.generatedtak.days")}
                      </TableCell>
                      <TableCell className="text-right">
                        {nft?.originalPrice
                          ? "€" +
                            formatNumberEuropean(Number(nft.originalPrice), 2)
                          : "0.00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="p-5 border-t px-20">
                <Button
                  className="w-full border-black"
                  onClick={() => {
                    navigate(`taks`);
                  }}
                >
                  {t("collection.analytics.generatedtak.view_all")}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
        {(data?.nftsForSale?.length ?? 0) > 0 && (
          <Card className="rounded-md bg-black p-5 flex justify-between items-center">
            <div>
              <p className="font-medium text-xl" style={{ color: "#43F3AE" }}>
                {t("collection.analytics.generatedtak.buyback_title", {
                  count: data.nftsForSale?.length,
                })}
              </p>
              <p className="font-medium text-sm text-white">
                {t("collection.analytics.generatedtak.buyback_subtitle")}
              </p>
            </div>
            <div>
              <Button
                variant={"outline2"}
                onClick={() => {
                  navigate(`buybacks`);
                }}
              >
                <Repeat className="mr-2" />
                {t("collection.analytics.generatedtak.buyback_button")}
              </Button>
            </div>
          </Card>
        )}
      </CardContent>
    </Card>
  );
}
