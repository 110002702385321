export const BOOKING_ENGINES = [
  // { value: "", text: "" },
  { text: "SiteMinder", value: "SiteMinder" },
  { text: "BookAssist", value: "BookAssist" },
  { text: "Avvio", value: "Avvio" },
  { text: "D-edge", value: "D-edge" },
  { text: "RoomCloud", value: "RoomCloud" },
  { text: "Vertical Booking", value: "Vertical Booking" },
  { text: "Profitroom", value: "Profitroom" },
  { text: "Hotelrunner", value: "Hotelrunner" },
  { text: "Guestcentric", value: "Guestcentric" },
  { text: "Cedyn", value: "Cedyn" },
  { text: "Simplotel", value: "Simplotel" },
  { text: "Net Affinity", value: "Net Affinity" },
  { text: "Synxis", value: "Synxis" },
  { text: "BookingExpert", value: "BookingExpert" },
  { text: "Ermes Hotels", value: "Ermes Hotels" },
  { text: "TravelClick", value: "TravelClick" },
  { text: "Blastness", value: "Blastness" },
  { text: "Simplebooking", value: "Simplebooking" },
  {
    text: "Hotel Cinque Stelle",
    value: "Hotel Cinque Stelle",
  },
  { text: "Bnext", value: "Bnext" },
  { text: "CultBooking", value: "CultBooking" },
  { text: "Ericsoft", value: "Ericsoft" },
  { text: "BeMyGuest", value: "BeMyGuest" },
  { text: "Octorate", value: "Octorate" },
  {
    text: "Reservation in Cloud",
    value: "Reservation in Cloud",
  },
  { text: "Hotel in Cloud", value: "Hotel in Cloud" },
  { text: "AmberGris", value: "AmberGris" },
  { text: "Dhynet", value: "Dhynet" },
  { text: "Witbooking", value: "Witbooking" },
  { text: "Slope", value: "Slope" },
  { text: "Passpartout", value: "Passpartout" },
  { text: "MyGuestCare", value: "MyGuestCare" },
  { text: "Zak/ WoDoo", value: "Zak/ WoDoo" },
  { text: "WebHotelier", value: "WebHotelier" },
  { text: "Iperbooking", value: "Iperbooking" },
  { text: "RateTiger", value: "RateTiger" },
  { text: "MyForecast", value: "MyForecast" },
  { text: "Bedzzle", value: "Bedzzle" },
  { text: "Parity Rate", value: "Parity Rate" },
  { text: "Cloudbeds", value: "Cloudbeds" },
  { text: "Mews", value: "Mews" },
  { text: "EasyConsulting", value: "EasyConsulting" },
  { text: "WuBook", value: "WuBook" },
  { text: "Beddy", value: "Beddy" },
  { text: "Nozio", value: "Nozio" },
  { text: "Sysdat", value: "Sysdat" },
  { text: "Kross Travel", value: "Kross Travel" },
  { text: "Booking Designer", value: "Booking Designer" },
  { text: "Roiback", value: "Roiback" },
  { text: "Bed-And-Breakfast", value: "Bed-And-Breakfast" },
  { text: "Amenitiz", value: "Amenitiz" },
  { text: "Scidoo", value: "Scidoo" },
  { text: "InReception", value: "InReception" },
  { text: "Mirai", value: "Mirai" },
  { text: "iHotelier", value: "iHotelier" },
  { text: "Hotetec", value: "Hotetec" },
  { text: "Neobookings", value: "Neobookings" },
  { text: "Paraty Tech", value: "Paraty Tech" },
  { text: "Hotusa", value: "Hotusa" },
  { text: "HyperGuest", value: "HyperGuest" },
  { text: "ProfitRoom", value: "ProfitRoom" },
  { text: "RateGain", value: "RateGain" },
  { text: "Quovai", value: "Quovai" },
];
