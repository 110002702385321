import { baseResponse } from "@/services/dataanalysismask";
import { useRbac } from "@/hooks/rbac";
import { useTranslation } from "react-i18next";
import { COLLECTION_PERMISSIONS, iCollection } from "@/services/collection";
import ButtonCreateNft from "@/components/collection/ButtonCreateNft";
import { useMemo } from "react";
import { formatNumberEuropean } from "@/utils/generic";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";

export default function Overview({
  data,
  collection,
}: {
  data: baseResponse;
  collection: iCollection;
}) {
  const { t } = useTranslation();
  const { can } = useRbac();
  const textOpacity = 0.6;

  const isPaymentValid = useMemo(
    () =>
      ["active", "trialing", "past_due"].includes(
        String(collection?.subscriptionStatus)
      ),
    [collection]
  );

  return (
    <div className="w-full rounded-xl p-5 flex gap-2 justify-between bg-gradient">
      <div className="w-[calc(50%-0.25rem)] flex flex-col justify-between">
        <div className="flex items-center justify-start gap-1 w-full">
          <p className="text-2xl font-medium">
            {t("analytics.overview.overview")}
          </p>
          <Popover>
            <PopoverTrigger asChild>
              <Info className="cursor-pointer" size={16} />
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <p>{t("analytics.overview.popover")}</p>
            </PopoverContent>
          </Popover>
        </div>
        <div>
          <p
            className="text-sm font-medium mb-1"
            style={{ opacity: textOpacity }}
          >
            {t("analytics.overview.generated_tak")}
          </p>
          <div className="flex flex-wrap gap-2 items-end">
            <p className="text-5xl font-medium">
              €{formatNumberEuropean(Number(data.grossAmount))}
            </p>
            <p
              className="text-3xl font-normal"
              style={{ opacity: textOpacity }}
            >
              {formatNumberEuropean(Number(data.nftAmount), 0)} Tak
            </p>
          </div>
        </div>
        <div className="h-[40px]"></div>
        {/* {can(
          [
            COLLECTION_PERMISSIONS.create_nft,
            COLLECTION_PERMISSIONS.update_nft,
          ],
          collection
        ) && (
          <div>
            <ButtonCreateNft
              collection={collection!}
              disableReason={
                !isPaymentValid
                  ? t("collection.invalid_payment_method")
                  : undefined
              }
            />
          </div>
        )} */}
      </div>
      <div className="w-[calc(50%-0.25rem)] flex flex-col gap-3">
        <div className="flex gap-3">
          {renderBlock(
            t("analytics.overview.average_window"),
            data?.averageBookingWindow
              ? `${formatNumberEuropean(data.averageBookingWindow, 1)} ${t(
                  "analytics.overview.days"
                )}`
              : "0"
          )}
          {renderBlock(
            t("analytics.overview.roomNights"),
            data?.roomNights ? `${data.roomNights}` : "0"
          )}
        </div>
        <div className="flex gap-3">
          {renderBlock(
            t("analytics.overview.averageLenghtOfStay"),
            data?.averageLenghtOfStay
              ? `${formatNumberEuropean(data.averageLenghtOfStay, 1)} ${t(
                  "analytics.overview.days"
                )}`
              : "0"
          )}
          {renderBlock(
            t("analytics.overview.averageDailyRate"),
            data?.averageDailyRate
              ? `€${formatNumberEuropean(data.averageDailyRate, 0)}`
              : "0"
          )}
        </div>
        {renderBlock(
          t("analytics.overview.averageBookingPrice"),
          data?.averageBookingPrice
            ? `€${formatNumberEuropean(data.averageBookingPrice, 0)}`
            : "0",
          "100%"
        )}
      </div>
    </div>
  );

  function renderBlock(title: string, value: string, width: string = "50%") {
    return (
      <div
        className="flex flex-col px-4 pt-3 pb-2 gap-1 rounded-md"
        style={{
          background: "rgba(255, 255, 255, 0.30)",
          width: width,
          border: "1px solid rgba(255, 255, 255, 0.30)",
        }}
      >
        <p className="text-xs font-medium">{title}</p>
        <p className="font-semibold text-2xl">{value}</p>
      </div>
    );
  }
}
