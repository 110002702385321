import { iCollection } from "@/services/collection";
import {
  getAnalysisMaskData,
  maskDataModuleResponse,
  MaskDataResponse,
  nftResponse,
} from "@/services/dataanalysismask";
import { useEffect, useMemo, useState } from "react";
import Customer from "./Customer";
import Overview from "./Overview";
import RevenueHighlight from "./RevenueHighlight";
import AppreciationStars from "./AppreciationStars";
import GeneratedTak from "./GeneratedTak";
import PerformanceSection from "./PerformanceSection";
import SidebarLatestNews from "./SidebarLatestNews";
import SidebarContactOurExpert from "./SidebarContactOurExpert";
import { Skeleton } from "@/components/ui/skeleton";
import { datetimeToString, isEmptyObject } from "@/utils/generic";
import { LockOpen, SquareArrowDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import widgetLoopit from "@/assets/img/loop_ita.png";
import widgetLoopen from "@/assets/img/loop_eng.png";
import widgetLoopes from "@/assets/img/loop_esp.png";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "@/lib/links";
import { useModal } from "@/context/ModalContext";
import GoToProModal from "./GoToProModal";
import { useTranslation } from "react-i18next";
import { useRbac } from "@/hooks/rbac";
import BuybackBanner from "./BuybackBanner";

export default function CollectionAnalyticsMaskTab({
  collection,
}: {
  collection: iCollection;
}) {
  const mockData: MaskDataResponse = {
    // base: {
    //   nftAmount: 321,
    //   grossAmount: 201507.96,
    //   averageBookingPrice: 627.7506542056075,
    //   roomNights: 771,
    //   averageDailyRate: 261.35922178988324,
    //   averageBookingWindow: 51.137071651090345,
    //   averageLenghtOfStay: 2.4018691588785046,
    // },
    revenue: {
      value: 1234.12,
      delta: 0.1234,
    },
    appreciation: {
      value: 4.1,
      delta: 0.3,
    },
    performance: {
      conversionRatePreTakyon: 0.002207233201651639,
      conversionRatePostTakyon: 0.0031597628013677877,
      clickOnOtherRate: 123,
      clickOnTakyonRate: 123,
      traffic: 12345,
      otaSavings: 1234,
    },
    nft: {
      nfts: [
        {
          _id: "a1b2c3d4-5e6f-7g8h-9i10-j11k12l13m14",
          _createdAt: "2024-03-17T14:30:45.000Z",
          originalPrice: 320,
          owner: "default-owner-1",
          collectionId: collection._id,
          lockDate: "2024-03-17T14:30:45.000Z",
          payload: {
            checkin: "2024-07-15T15:00:00.000Z",
            checkout: "2024-07-19T11:00:00.000Z",
            rooms: [
              {
                guestsAdults: 2,
                guestsKids: 1,
              },
            ],
          },
        },
        {
          _id: "n15o16p17-q18r-s19t-u20v-w21x22y23z24",
          _createdAt: "2024-05-22T09:15:30.000Z",
          originalPrice: 180,
          owner: "default-owner-2",
          collectionId: collection._id,
          lockDate: "2024-05-22T09:15:30.000Z",
          payload: {
            checkin: "2024-09-03T14:00:00.000Z",
            checkout: "2024-09-06T10:00:00.000Z",
            rooms: [
              {
                guestsAdults: 1,
                guestsKids: 0,
              },
            ],
          },
        },
        {
          _id: "aa25bb26-cc27-dd28-ee29-ff30gg31hh32",
          _createdAt: "2024-08-09T18:45:00.000Z",
          originalPrice: 420,
          owner: "default-owner-3",
          collectionId: collection._id,
          lockDate: "2024-08-09T18:45:00.000Z",
          payload: {
            checkin: "2024-12-20T16:00:00.000Z",
            checkout: "2024-12-27T12:00:00.000Z",
            rooms: [
              {
                guestsAdults: 2,
                guestsKids: 2,
              },
            ],
          },
        },
      ],
      bookingsAmount: [
        { month: 11, year: 2023, takyon: 150, other: 50, takscript: 30 },
        { month: 0, year: 2024, takyon: 120, other: 70, takscript: 25 },
        { month: 1, year: 2024, takyon: 150, other: 30, takscript: 35 },
        { month: 2, year: 2024, takyon: 250, other: 40, takscript: 45 },
        { month: 3, year: 2024, takyon: 60, other: 50, takscript: 20 },
        { month: 4, year: 2024, takyon: 40, other: 40, takscript: 15 },
        { month: 5, year: 2024, takyon: 170, other: 90, takscript: 40 },
        { month: 6, year: 2024, takyon: 190, other: 30, takscript: 50 },
        { month: 7, year: 2024, takyon: 140, other: 50, takscript: 35 },
        { month: 8, year: 2024, takyon: 100, other: 40, takscript: 25 },
        { month: 9, year: 2024, takyon: 130, other: 50, takscript: 30 },
        { month: 10, year: 2024, takyon: 80, other: 70, takscript: 20 },
      ],
      nftsForSale: [],
    },
    customer: {
      devices: {
        mobile: 0.1234,
        desktop: 0.2345,
        tablet: 0.12,
      },
      countries: {
        ar: 0.14158334415443882,
        it: 0.10933644981171277,
        en: 0.7359217417651387,
        fr: 0.0005626974851750855,
        pl: 0.0007791195948578107,
      },
      people: {
        single: 0.0529595015576324,
        couple: 0.7850467289719626,
        group: 0.07476635514018691,
        family: 0.06542056074766354,
      },
    },
  };
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { isSuperAdmin } = useRbac();

  const navigate = useNavigate();
  const { showModal } = useModal();
  const [baseData, setBaseData] = useState<maskDataModuleResponse>();
  const [analyticsData, setAnalyticsData] =
    useState<MaskDataResponse>(mockData);
  const [isLoading, setLoading] = useState(true);

  const isFreeCollection = collection.isFree;
  const isScriptEnabled = collection.scriptEnabled;
  const analyticsVisible = collection.analyticsVisible;

  const [noTracking, setNoTracking] = useState(false);

  const freeClasses =
    "before:rounded-xl p-5 relative before:absolute before:inset-0 before:bg-gradient-to-b before:from-[#151515] before:via-[#151515] before:via-10% before:to-[#8b8b8b] before:opacity-70 before:z-10";

  const noTrackingClasses = "rounded-xl border";

  const loadMaskData = async () => {
    try {
      setLoading(true);
      const { base, ...restAnalytics } = await getAnalysisMaskData({
        collectionId: collection._id,
      });
      // base data always available from the api
      setBaseData(base);

      // console.log("restAnalytics", base, restAnalytics);

      // console.log(
      //   "collection settings",
      //   isFreeCollection,
      //   isScriptEnabled,
      //   isSuperAdmin
      // );

      if (isFreeCollection) {
        setAnalyticsData(mockData);
      }
      // isPro
      else {
        if (isScriptEnabled) {
          if (!isSuperAdmin) {
            const nftData = restAnalytics.nft as nftResponse;
            // for the graph we use the total of taks stored in the database
            // total takscript is the total of taks taken from the script
            const totalTakscript =
              nftData.bookingsAmount?.reduce(
                (total: number, item: { takscript?: number }) =>
                  total + (item.takscript ?? 0),
                0
              ) ?? 0;

            if (
              analyticsVisible === true
              // &&
              // totalTakscript &&
              // totalTakscript > 3
            ) {
              setAnalyticsData(restAnalytics);
            } else {
              console.log("no tracking", { analyticsVisible, totalTakscript });
              setNoTracking(true);
              setAnalyticsData(mockData);
            }
          } else {
            setAnalyticsData(restAnalytics);
          }
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("Error getting mask data", error);
    }
  };

  const AnalyticsDataDisplay = ({ data }: { data: MaskDataResponse }) => {
    return (
      <>
        {Object.keys(data).map((key) => {
          const dataEmpty = isEmptyObject((data as any)[key]);
          if (dataEmpty) return null;
          switch (key) {
            case "revenue":
              return (
                <RevenueHighlight
                  key={key}
                  value={(data as any)[key].value}
                  unit={"€"}
                  delta={(data as any)[key].delta}
                />
              );
            case "appreciation":
              return (
                <AppreciationStars
                  key={key}
                  value={(data as any)[key].value}
                  delta={(data as any)[key].delta}
                />
              );
            case "performance":
              return <PerformanceSection key={key} data={(data as any)[key]} />;
            case "customer":
              return <Customer key={key} data={(data as any)[key]} />;
            case "nft":
              return (
                <GeneratedTak
                  key={key}
                  data={(data as any)[key]}
                ></GeneratedTak>
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const startTrackDate = collection.startTrackingDate;
  const collectionCreationDate = collection._createdAt;

  const buybacks = useMemo(() => {
    return (analyticsData.nft as any)?.nftsForSale ?? [];
  }, [analyticsData]);

  useEffect(() => {
    loadMaskData();
  }, []);

  return isLoading ? (
    <div className="flex gap-4">
      <div className="flex flex-wrap gap-2 w-3/4">
        <Skeleton className="w-full h-[286px]" />
        <div className="flex w-full gap-2">
          <Skeleton className="w-full h-[160px]" />
          <Skeleton className="w-full h-[160px]" />
        </div>
        <Skeleton className="w-full h-[401px]" />
        <Skeleton className="w-full h-[90px]" />
        <Skeleton className="w-full h-[521px]" />
      </div>
      <div className="h-full flex flex-col w-1/4 gap-2">
        {[1, 2, 3].map((index) => (
          <Skeleton key={index} className="w-full h-[167px]" />
        ))}
      </div>
    </div>
  ) : (
    <div className="flex gap-4">
      <div
        className="flex flex-wrap gap-4 w-3/4"
        style={{ minHeight: "520px" }}
      >
        <div className="w-full flex-wrap flex gap-4">
          {collectionCreationDate && (
            <p className="flex gap-1 text-xs text-gray-600">
              <SquareArrowDown size={16} />
              {t("analytics.collection_creation_date")}:{" "}
              {datetimeToString(collectionCreationDate, 1, true, false)}
            </p>
          )}
          {baseData && (
            <Overview data={baseData as any} collection={collection} />
          )}
          {buybacks && buybacks.length > 0 && (
            <BuybackBanner nftsForSale={buybacks} />
          )}
          {startTrackDate && (
            <p className="flex gap-1 text-xs text-gray-600">
              <SquareArrowDown size={16} />
              {t("analytics.start_tracking_date")}:{" "}
              {datetimeToString(startTrackDate, 1, true, false)}
            </p>
          )}
          <div
            className={`w-full ${
              isFreeCollection || !isScriptEnabled ? freeClasses : ""
            } ${noTracking ? noTrackingClasses : ""}`}
          >
            <div className="w-full">
              {!isFreeCollection ? (
                !isScriptEnabled ? (
                  <>
                    <div className="w-full flex flex-col items-center justify-center gap-4 sticky top-[40vh] z-[18] mb-6 bg-opacity-80 py-4">
                      <div className="w-full flex flex-col items-center justify-center">
                        <p className="text-2xl font-medium text-white">
                          {t("analytics.notag_title")}
                        </p>
                        <p className="text-white">
                          {t("analytics.notag_subtitle")}
                        </p>
                      </div>
                      <Button
                        onClick={() => navigate(getLink(LINK_TYPE.DEVELOPER))}
                        variant={"outline2"}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.3) 6px 6px 6px 0px",
                        }}
                      >
                        {t("analytics.notag_button")}
                      </Button>
                    </div>
                    <div className="flex flex-wrap gap-4 blur-[6px]">
                      <AnalyticsDataDisplay data={analyticsData} />
                    </div>
                  </>
                ) : noTracking ? (
                  <>
                    <div className="w-full flex flex-col items-center justify-center gap-4 sticky top-[40vh] z-[18] mb-6 bg-opacity-80 py-4">
                      <div className="w-full flex flex-col items-center justify-center overflow-hidden px-5">
                        <p className="text-2xl font-medium mt-4">
                          {t("analytics.notrack_title")}
                        </p>
                        <p className="text-1xl mb-14">
                          {t("analytics.notrack_subtitle")}
                        </p>
                        <div className="w-full overflow-hidden mt-7 transform scale-[1.7]">
                          <div className="flex gap-0 animate-scroll">
                            <div className="flex-none w-full">
                              <img
                                src={
                                  currentLanguage === "it"
                                    ? widgetLoopit
                                    : currentLanguage === "en"
                                    ? widgetLoopen
                                    : widgetLoopes
                                }
                                alt="widget loop"
                              />
                            </div>
                            <div className="flex-none w-full">
                              <img
                                src={
                                  currentLanguage === "it"
                                    ? widgetLoopit
                                    : currentLanguage === "en"
                                    ? widgetLoopen
                                    : widgetLoopes
                                }
                                alt="widget loop"
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "20px" }}></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-wrap gap-4">
                    <AnalyticsDataDisplay data={analyticsData} />
                  </div>
                )
              ) : (
                <>
                  <div className="w-full flex flex-col items-center justify-center gap-4 sticky top-[40vh] z-[18] mb-6 bg-opacity-80 py-4">
                    <div className="w-full flex flex-col items-center justify-center">
                      <p className="text-2xl font-medium text-white">
                        {t("analytics.nopro_title")}
                      </p>
                      <p className="text-white">
                        {t("analytics.nopro_subtitle")}
                      </p>
                    </div>
                    <Button
                      onClick={() =>
                        showModal(<GoToProModal />, {
                          title: t("pro_modal.title"),
                          description: "",
                          className: "max-w-max",
                        })
                      }
                      variant={"doubleBorder"}
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.3) 6px 6px 6px 0px",
                      }}
                    >
                      <LockOpen size={16} />
                      {t("analytics.nopro_button")}
                    </Button>
                  </div>
                  <div className="flex flex-wrap gap-4 blur-[6px]">
                    <AnalyticsDataDisplay data={analyticsData} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full flex flex-col w-1/4">
        <div className="w-full flex flex-col gap-4" style={{ height: "300px" }}>
          <SidebarLatestNews />
          <SidebarContactOurExpert isPro={!isFreeCollection} />
          {/* <AnalyticsBanner /> */}
        </div>
      </div>
    </div>
  );
}
