import { useEffect, useState } from "react";
import { useModal } from "@/context/ModalContext";
import { savePartner, iPartner } from "@/services/partners";
import { useForm } from "react-hook-form";
import { Form } from "@/components/ui/form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { DatePicker } from "@/components/form_fields/DatePicker";
import MapsAutocomplete from "@/components/mapsautocomplete/MapsAutocomplete";
import { Loader2, LucideStar } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "i18next";
import Upload from "@/components/form_fields/Upload";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import { BookingEngine } from "@/utils/common";

interface CreatePartnerProps {
  _partner?: iPartner;
  onSuccess: Function;
}

export default function ModalPartner({
  _partner,
  onSuccess,
}: CreatePartnerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [partner, setPartner] = useState<iPartner>({} as any);
  const { hideModal } = useModal();
  const [logo, setLogo] = useState("");
  const [image, setImage] = useState("");
  const [censusLocation, setCensusLocation] = useState<any>(null);

  useEffect(() => {
    if (_partner) {
      setPartner(_partner);
      if (_partner.logo) {
        setLogo(_partner.logo);
      }
      if (_partner.image) {
        setImage(_partner.image);
      }
    }
    // eslint-disable-next-line
  }, []);

  // const submit = async (e: FormEvent<HTMLFormElement>) => {
  const submit = async (data: z.infer<typeof formSchema>) => {
    console.log("data - submit : ", data);
    // e.preventDefault();
    // let locationValue = data.location;
    // data.location = {
    //   label: locationValue,
    //   value: {
    //     description: locationValue,
    //   },
    // };
    // console.log("partner - submit : ", partner);
    setIsLoading(true);
    try {
      //   await savePartner(partner);
      await savePartner({
        ...data,
        location: censusLocation,
        image: image,
        logo: logo,
        _id: partner._id,
      });
      await onSuccess({});
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleAddressSelection = (selectedAddress: any) => {
    if (!selectedAddress) {
      form.resetField("location");
    } else {
      setCensusLocation(selectedAddress);
      form.setValue("location", selectedAddress.label);
    }
  };

  const formSchema = z.object({
    _id: z.string().optional(),
    name: z.string(),
    stars: z.string(),
    website: z.string(),
    roomsAmount: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string()
      )
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), {
        message: "Rooms amount must be a number",
      })
      .refine((val) => val >= 0, {
        message: "Rooms amount must be non-negative",
      }),
    // location: z.string().or(z.object()),
    location:
      z.string() ||
      z.object({
        label: z.string(),
        value: z.object({ description: z.string() }),
      }),
    // locationType: z.string().optional(),
    liveDate: z.date(),
    signDate: z.date(),
    paymentStartDate: z.date(),
    group: z.string().optional(),
    groupId: z.string(),
    collectionId: z.string(),
    accommodationType: z.string(),
    revenueStrategy: z.string(),
    destinationType: z.string(),
    apiIntegration: z.boolean().default(false).optional(),
    isOpen: z.boolean().default(false).optional(),
    bookingEngine: z.string(),
    logo: z.any(),
    image: z.any(),
  });
  //   console.log("_partner : ", _partner);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // name: partner?.name,
      // stars: partner?.stars,
      // website: partner?.website,
      // roomsAmount: partner?.roomsAmount,
      // location: partner?.location?.label,
      // // locationType: partner?.locationType,
      // liveDate: partner?.liveDate,
      // signDate: partner?.signDate,
      // paymentStartDate: partner?.paymentStartDate,
      // group: partner?.group,
      // groupId: partner?.groupId,
      // collectionId: partner?.collectionId,
      // accommodationType: partner?.accommodationType,
      // revenueStrategy: partner?.revenueStrategy,
      // destinationType: partner?.destinationType,
      // apiIntegration: partner?.apiIntegration || false,
      // isOpen: partner?.isOpen || false,
      // bookingEngine: partner?.bookingEngine,
      // logo: partner?.logo,
      // image: partner?.image,
    },
  });

  useEffect(() => {
    console.log("date", partner?.signDate, typeof partner?.signDate);
    console.log(
      "date",
      partner?.paymentStartDate,
      typeof partner?.paymentStartDate
    );
    console.log("liveDate", partner?.liveDate, typeof partner?.liveDate);
    form.reset({
      name: partner?.name,
      stars: partner?.stars,
      website: partner?.website,
      roomsAmount: partner?.roomsAmount,
      location: partner?.location?.label,
      // locationType: partner?.locationType
      liveDate: partner?.liveDate ? new Date(partner?.liveDate) : undefined,
      signDate: partner?.signDate ? new Date(partner?.signDate) : undefined,
      paymentStartDate: partner?.paymentStartDate
        ? new Date(partner?.paymentStartDate)
        : undefined,
      group: partner?.group,
      groupId: partner?.groupId,
      collectionId: partner?.collectionId,
      accommodationType: partner?.accommodationType,
      revenueStrategy: partner?.revenueStrategy,
      destinationType: partner?.destinationType,
      apiIntegration: partner?.apiIntegration || false,
      isOpen: partner?.isOpen || false,
      bookingEngine: partner?.bookingEngine,
      logo: partner?.logo,
      image: partner?.image,
    });
    setCensusLocation(partner?.location);
  }, [partner, form]);

  const onNewLogoUploaded = (uploadedImages: string[], key: string) => {
    // const newImages = [...images, ...uploadedImages];
    // setImages(newImages);
    // console.log("uploadedImages - onNewLogoUploaded : ", uploadedImages);
    // setPartner({ ...partner, [key]: uploadedImages[0] });
    setLogo(uploadedImages[0]);
  };
  const onNewImagesUploaded = (uploadedImages: string[], key: string) => {
    // const newImages = [...images, ...uploadedImages];
    // setImages(newImages);
    // console.log("onNewImagesUploaded - onNewLogoUploaded : ", uploadedImages);
    // setPartner({ ...partner, [key]: uploadedImages[0] });
    setImage(uploadedImages[0]);
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-2"
        // onSubmit={submit}
        onSubmit={form.handleSubmit(submit)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.name")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("partners.name")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="stars"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.stars")} {t("form.required")}
              </FormLabel>
              <Select
                {...field}
                onValueChange={field.onChange}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("partners.stars")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem key={0} value="na">
                    <span>N/A</span>
                  </SelectItem>
                  {[1, 2, 3, 4, 5, 6].map((value) => (
                    <SelectItem key={value} value={String(value)}>
                      <div className="flex items-center">
                        {value === 6 ? (
                          <>
                            {[...Array(5)].map((_, i) => (
                              <LucideStar key={i} className="w-3 h-3" />
                            ))}
                            <span className="ml-2">L</span>
                          </>
                        ) : (
                          [...Array(value)].map((_, i) => (
                            <LucideStar key={i} className="w-3 h-3" />
                          ))
                        )}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.website")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("partners.website")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="roomsAmount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.roomsAmount")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("partners.roomsAmount")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="location"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.location")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <MapsAutocomplete
                  defaultValue={censusLocation}
                  onSelect={handleAddressSelection}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="liveDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.liveDate")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <div>
                  <DatePicker className="w-full" {...field} />
                </div>
                {/* <Controller
                  control={form.control}
                  name="liveDate"
                  render={({ field }) => (
                    <DatePicker
                      placeholder={t("form.pick_date")}
                      {...field}
                      value={field.value}
                      onChange={(date: string) => {
                        field.onChange(date);
                      }}
                    />
                  )}
                /> */}
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="signDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.signDate")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <div>
                  {/* <Input placeholder="Data della firma" {...field} /> */}
                  <DatePicker className="w-full" {...field} />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="paymentStartDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.paymentStartDate")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <div>
                  {/* <Input placeholder="Data della firma" {...field} /> */}
                  <DatePicker className="w-full" {...field} />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-4 my-4">
          <FormField
            control={form.control}
            name="logo"
            render={({ field }) => (
              <FormItem className="flex justify-between gap-2 items-center w-full border p-4">
                <FormLabel>
                  {t("partners.logo")} {t("form.required")}
                </FormLabel>
                <FormControl className="self-start">
                  {logo && !isLoading ? (
                    <img
                      src={logo}
                      style={{ width: "100px" }}
                      alt="partner logo"
                    />
                  ) : !logo && !isLoading ? (
                    <p>Missing logo</p>
                  ) : (
                    <Loader2 />
                  )}
                </FormControl>
                <FormControl>
                  <Upload
                    onUpload={(images: string[]) =>
                      onNewLogoUploaded(images, "logo")
                    }
                    onStartLoading={() => setIsLoading(true)}
                    onEndLoading={() => setIsLoading(false)}
                    multiple={false}
                    accept="image/png, image/jpeg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem className="flex justify-between gap-2 items-center w-full border p-4">
                <FormLabel>
                  {t("partners.image")} {t("form.required")}
                </FormLabel>
                <FormControl className="self-start">
                  {image && !isLoading ? (
                    <img
                      src={image}
                      style={{ width: "100px" }}
                      alt="partner image"
                    />
                  ) : !image && !isLoading ? (
                    <p>Missing image</p>
                  ) : (
                    <Loader2 />
                  )}
                </FormControl>
                <FormControl>
                  <Upload
                    onUpload={(images: string[]) =>
                      onNewImagesUploaded(images, "image")
                    }
                    onStartLoading={() => setIsLoading(true)}
                    onEndLoading={() => setIsLoading(false)}
                    multiple={false}
                    accept="image/png, image/jpeg"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex w-full gap-2">
          <FormField
            control={form.control}
            name="group"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("partners.group")} {t("form.required")}
                </FormLabel>
                <Select
                  {...field}
                  onValueChange={(value) => field.onChange(value)}
                  value={field.value}
                  // onValueChange={field.onChange}
                  // value={field.value}
                  // {...field}
                  // onValueChange={field.onChange}
                  // value={field.value}
                  // onValueChange={(value) => {
                  //   setPartner({ ...partner, group: value });
                  //   field.onChange;
                  // }}
                  // value={partner.group}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={"Select a value"} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {[
                      // { value: "", text: "" },
                      { value: "BWH", text: "BWH" },
                    ].map((s, index) => (
                      <SelectItem
                        key={"item" + index}
                        value={s.value ?? t("form.select")}
                      >
                        {s.text}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="groupId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("partners.groupId")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("partners.groupId")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="collectionId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.collectionId")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("partners.collectionId")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="accommodationType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.accommodationType")} {t("form.required")}
              </FormLabel>
              <Select
                {...field}
                onValueChange={field.onChange}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t("partners.accommodationType")}
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {[
                    { value: "hotel", text: "Hotel" },
                    { value: "extra-hotel", text: "Extra Hotel" },
                  ].map((s, index) => (
                    <SelectItem
                      key={"item" + index}
                      value={s.value ?? t("form.select")}
                    >
                      {s.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="revenueStrategy"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.revenueStrategy")} {t("form.required")}
              </FormLabel>
              <Select
                {...field}
                onValueChange={field.onChange}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("form.select")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {[
                    { value: "only-not-ref", text: "Only Not Ref" },
                    {
                      value: "additional-not-ref",
                      text: "Additional Not Ref",
                    },
                  ].map((s, index) => (
                    <SelectItem
                      key={"item" + index}
                      value={s.value ?? t("form.select")}
                    >
                      {s.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="destinationType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.destinationType")} {t("form.required")}
              </FormLabel>
              <Select
                {...field}
                onValueChange={field.onChange}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("form.select")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {[
                    // { value: "", text: "" },

                    { value: "city", text: "City" },
                    { value: "lake", text: "Lake" },
                    { value: "mountain", text: "Mountain" },
                    { value: "sea", text: "Sea" },
                    { value: "wellness", text: "Wellness" },
                  ].map((s, index) => (
                    <SelectItem
                      key={"item" + index}
                      value={s.value ?? t("form.select")}
                    >
                      {s.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="apiIntegration"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  // checked={partner?.apiIntegration}
                  // onCheckedChange={(e: any) => {
                  //   //   setPartner({ ...partner, apiIntegration: e });
                  //   field.onChange;
                  // }}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{t("partners.apiIntegration")}</FormLabel>
                {/* <FormDescription>
                      You can manage your mobile notifications in the{" "}
                      </FormDescription> */}
              </div>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="isOpen"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{t("partners.isOpen")}</FormLabel>
                {/* <FormDescription>
                      You can manage your mobile notifications in the{" "}
                      </FormDescription> */}
              </div>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="bookingEngine"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("partners.bookingEngine")} {t("form.required")}
              </FormLabel>
              <Select
                {...field}
                onValueChange={field.onChange}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("form.select")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.values(BookingEngine)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((e, index) => (
                      <SelectItem
                        key={"item" + index}
                        value={e ?? t("form.select")}
                      >
                        {e}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-between flex-row-reverse gap-4">
          <LoadingSubmitButton
            type="submit"
            isLoading={isLoading}
            variant="takyon"
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
