import { iPaginationProps, Pagination } from "@/utils/Pagination";
import axios from "axios";
import { iNft } from "./nft";

export enum DATA_ANALYSIS_MODULE_TYPE {
  BASE = "base",
  REVENUE = "revenue",
  APPRECIATION = "appreciation",
  PERFORMANCE = "performance",
  NFT = "nft",
  CUSTOMER = "customer",
}

export type baseConfig = {};
export type baseResponse = {
  nftAmount?: number;
  grossAmount?: number;
  averageBookingWindow?: number;
  roomNights?: number;
  averageDailyRate?: number;
  averageBookingPrice?: number;
  averageLenghtOfStay?: number;
};

export type revenueConfig = {};
export type revenueResponse = {
  value?: number;
  delta?: number;
};

export type appreciationConfig = {};
export type appreciationResponse = {
  value?: number;
  delta?: number;
};

export type performanceConfig = {};
export type performanceResponse = {
  conversionRatePreTakyon?: number;
  conversionRatePostTakyon?: number;
  clickOnTakyonRate?: number;
  clickOnOtherRate?: number;
  traffic?: number;
  otaSavings?: number;
};

export type nftConfig = {};
export type nftResponse = {
  nfts?: iNft[];
  bookingsAmount?: {
    month: number;
    year: number;
    takyon: number;
    other: number;
    takscript: number;
  }[];
  nftsForSale?: iNft[];
};

export type customerConfig = {};
export type customerResponse = {
  devices?: {
    mobile?: number;
    desktop?: number;
    tablet?: number;
  };
  countries?: {
    [key in string]?: number;
  };
  people?: {
    single?: number;
    couple?: number;
    group?: number;
    family?: number;
  };
};

export type maskDataModuleResponse =
  | baseResponse
  | revenueResponse
  | appreciationResponse
  | performanceResponse
  | nftResponse
  | customerResponse;

export type DataAnalysisModule =
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.BASE;
      config: baseConfig;
    }
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.REVENUE;
      config: revenueConfig;
    }
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.APPRECIATION;
      config: appreciationConfig;
    }
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.PERFORMANCE;
      config: performanceConfig;
    }
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.NFT;
      config: nftConfig;
    }
  | {
      name: DATA_ANALYSIS_MODULE_TYPE.CUSTOMER;
      config: customerConfig;
    };

export type MaskDataResponse = {
  [key in DATA_ANALYSIS_MODULE_TYPE]?: maskDataModuleResponse;
};

export default interface iDataAnalysisMask {
  _id: string;
  _updatedAt: string;
  name: string;
  modules: DataAnalysisModule[];
}

export async function getAnalysisMask(id: string): Promise<iDataAnalysisMask> {
  const res = await axios.get("/dataanalysismask/" + id);
  return res.data;
}

export async function getAnalysisMaskPaginate(
  params: iPaginationProps
): Promise<Pagination<iDataAnalysisMask>> {
  const res = await axios.get("/dataanalysismask/paginate", { params });
  return res.data;
}

export async function createAnalysisMask(params: {}): Promise<iDataAnalysisMask> {
  const res = await axios.post("/dataanalysismask", params);
  return res.data;
}

export async function editAnalysisMask(params: {
  dataAnalysisMaskId: string;
  name: string;
  modules: DataAnalysisModule[];
}): Promise<iDataAnalysisMask> {
  const res = await axios.patch("/dataanalysismask", params);
  return res.data;
}

export async function deleteAnalysisMask(params: {
  dataAnalysisMaskId: string;
}): Promise<iDataAnalysisMask> {
  const res = await axios.delete("/dataanalysismask", { data: params });
  return res.data;
}

export async function getAnalysisMaskData(params: {
  collectionId: string;
}): Promise<MaskDataResponse> {
  const res = await axios.get("/dataanalysismask/data", { params });
  return res.data;
}
