import { iCollection } from "@/services/collection";
import { iBid } from "@/services/nft";
import { datetimeToString } from "@/utils/generic";
import { ColumnDef } from "@tanstack/react-table";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Copy, LucideEye, MoreVertical } from "lucide-react";
import BuybackActionForm from "@/components/offer/BuybackActionForm";
import { useModal } from "@/context/ModalContext";
import { TFunction } from "i18next";
import helpers from "@/components/helpers/helpers";
import CopyId from "@/components/helpers/CopyId";
import DetailHotel from "@/components/collection/DetailHotel";
import GetBidStatus from "@/components/offer/GetBidStatus";

export const getColumns = (
  collection: iCollection,
  t: TFunction
): ColumnDef<iBid>[] => {
  return [
    // {
    //   header: t("offer.id"),
    //   accessorKey: "_id",
    //   cell: ({ getValue }) => <CopyId resourceId={getValue() as string} />,
    // },
    {
      header: t("nft.taks_reference"),
      accessorFn: (offer) => {
        return offer._nft?.payload?.reference;
      },
    },
    {
      header: t("offer.status"),
      accessorKey: "status",
      cell: ({ getValue, row }) => {
        const bid = row.original as iBid;
        return (
          <div className="flex gap-1 items-center">
            {GetBidStatus(bid.status)}
          </div>
        );
      },
    },
    {
      header: t("offer.valid_until"),
      accessorFn: (offer) =>
        datetimeToString(offer.expireDate, undefined, true),
    },
    {
      header: `${t("offer.originalPrice")} (€)`,
      accessorFn: (offer) => Number(offer._nft?.originalPrice).toFixed(2),
    },
    {
      header: `${t("offer.amount")} (€)`,
      accessorFn: (offer) => Number(offer.amount).toFixed(2),
    },
    {
      header: t("nft.checkin"),
      accessorFn: (offer) =>
        datetimeToString(offer._nft?.payload?.checkin, undefined, true),
    },
    {
      header: t("nft.checkout"),
      accessorFn: (offer) =>
        datetimeToString(offer._nft?.payload?.checkout, undefined, true),
    },
    // {
    //   header: t("offer.rooms"),
    //   accessorFn: (offer) => {
    //     offer._nft?.payload?.rooms?.map((r: any) => r.name).join(",");
    //   },
    // },
    {
      header: t("offer.guests"),
      accessorFn: (offer) => {
        return offer._nft?.payload?.rooms?.reduce(
          (total: number, room: any) => {
            return total + (room.guestsAdults ?? 0) + (room.guestsKids ?? 0);
          },
          0
        );
      },
    },

    {
      id: "actions",
      accessorKey: "id",
      header: t("offer.actions"),
      cell: function render({ getValue, row }) {
        const { showModal } = useModal();

        return (
          <>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(
                  <DetailHotel
                    nft={row.original._nft}
                    collectionCensus={row.original._collection.census}
                  />,
                  {
                    title:
                      t("nft.details_title") +
                      " - " +
                      row.original._nft.payload.reference,
                    description: null,
                  }
                );
              }}
            >
              <LucideEye size={16} />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>{t("tables.delete")}</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];
};
