import { t } from "i18next";
import { H3, H4 } from "../typography/Typography";
import img from "@/assets/img/dash_no_group_no_collection.png";

export default function NoGroup() {
  const email = "partner.support@takyon.io";

  return (
    <main className="p-4 md:gap-8 md:px-14 md:py-8">
      <div className="mb-20 flex gap-20">
        <div className="flex-1">
          <div>
            <H3 className="font-semibold">
              {t("group.no_organization_title")}
            </H3>
            <p>{t("group.no_organization_text")}</p>
            <div className="border-b border-gray-300 mt-8 mb-8"></div>
          </div>
          <ul className="list-disc pl-6 text-sm">
            <li className="mb-5">
              <p className="font-semibold">
                {t("group.no_organization_context_row1_title")}
              </p>
              <p className="text-gray-600">
                {t("group.no_organization_context_row1_text")}
              </p>
            </li>
            <li>
              <p className="font-semibold">
                {t("group.no_organization_context_row2_title")}
              </p>
              <p className="text-gray-600">
                {t("group.no_organization_context_row2_text")}
              </p>
            </li>
          </ul>
          <p className="mt-12 text-sm">
            {t("group.no_organization_footer_text")}
            <span>
              <a className="font-semibold underline" href={`mailto:${email}`}>
                {t("group.no_organization_footer_contact")}
              </a>
            </span>
          </p>
        </div>

        <img className="w-[400px]" src={img} />
      </div>
    </main>
  );
}
