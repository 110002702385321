import * as React from "react";
// import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Column, Table } from "@tanstack/react-table";

import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { CheckIcon, PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
interface FilterSelectProps<TData, TValue> {
  table: Table<TData>;
  filter?: any;
}

export function FilterSelect<TData, TValue>({
  filter,
  table,
}: FilterSelectProps<TData, TValue>) {
  const {
    type,
    column,
    options,
  }: {
    type: string;
    column: string;
    options: {
      label: string;
      value: string;
      icon?: React.ComponentType<{ className?: string }>;
    }[];
  } = filter;
  const columnInstance = column ? table.getColumn(column) : null;
  const facets = columnInstance?.getFacetedUniqueValues(); // it works only if used in client-side

  const [selectedValues, setSelectedValues] = React.useState<Set<string>>(
    new Set(columnInstance?.getFilterValue() as string[])
  );

  React.useEffect(() => {
    const activeFilters = Array.from(selectedValues);
    filter.onChange(activeFilters);
  }, [selectedValues]);

  const handleReset = () => {
    setSelectedValues(new Set());
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          {filter.title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={filter.title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      setSelectedValues(new Set(selectedValues));
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected
                          ? "bg-primary text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={handleReset}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

interface FilterToogleProps<TData> {
  table: Table<TData>;
  filter?: any;
}

export function FilterToogle({ filter, table }: FilterToogleProps<any>) {
  const [active, setActive] = React.useState(false);

  const columnInstance = filter.column ? table.getColumn(filter.column) : null;

  React.useEffect(() => {
    if (active) {
      columnInstance?.setFilterValue(true);
    } else {
      columnInstance?.setFilterValue(undefined);
    }
  }, [active]);

  return (
    <div>
      <Button
        size="sm"
        className="h-8 border-dashed cursor-pointer"
        variant={active ? "takyon" : "outline"}
        onClick={() => setActive(!active)}
      >
        {filter.title}
        {active && (
          <button
            className="ml-2"
            onClick={(e) => {
              e.stopPropagation();
              setActive(false);
            }}
          >
            ✕
          </button>
        )}
      </Button>
    </div>
  );
}

interface FilterToogleProps<TData> {
  table: Table<TData>;
  filter?: any;
}

export function FilterButtonsSelect({ filter, table }: FilterToogleProps<any>) {
  const [selection, setSelection] = React.useState();
  const { t } = useTranslation();

  return (
    <div className="flex gap-1">
      {filter.options.map((option: any, key: number) => (
        <Button
          key={`f-btn-sel-${key}`}
          variant={option.value === selection ? "takyon" : "default"}
          onClick={() => {
            setSelection(option.value);
            filter.onChange(option.value);
          }}
          size={"sm"}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}

// filters wrapper component that iterates over filters and renders the appropriate filter component
export default function DataTableToolbarFilters<T>({
  filters,
  table,
}: {
  table: Table<T>;
  filters: any[];
}) {
  return (
    <div className="flex items-center gap-1">
      {filters.map((filter, key) => {
        switch (filter.type) {
          case "toggle":
            return (
              <FilterToogle key={`filt-${key}`} table={table} filter={filter} />
            );
          case "select":
            return (
              <FilterSelect key={`filt-${key}`} table={table} filter={filter} />
            );
          case "select-buttons":
            return (
              <FilterButtonsSelect
                key={`filt-${key}`}
                table={table}
                filter={filter}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
