import ErrorPage from "@/components/errors/ErrorPage";
import { PaymentMethod } from "@/components/payment-method/PaymentMethod";
import { H3 } from "@/components/typography/Typography";
import { Skeleton } from "@/components/ui/skeleton";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { getPaymentMethod, iPaymentMethod } from "@/services/payment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function PaymentMethodPage() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [pm, setPm] = useState<iPaymentMethod>();

  const { id } = useParams();

  const load = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const pm = await getPaymentMethod(id);
      setPm(pm);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const { setTitle } = useSectionTitle();
  useEffect(() => {
    if (pm) return setTitle(pm.name);
    setTitle(t("paymentmethods.title"));
  }, [setTitle, pm]);

  if (isLoading)
    return (
      <>
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] w-[730px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[122px] rounded-lg" />
        </div>
      </>
    );

  if (!pm) return <ErrorPage message={t("error.not_found")} />;

  return (
    <main className="p-4 md:gap-8 md:px-14 md:py-8">
      <H3>{pm.name}</H3>
      <div className="mt-4">
        <PaymentMethod payment={pm} />
      </div>
    </main>
  );
}
