import { iUser } from "@/services/auth";
import { datetimeToString } from "@/utils/generic";

const helpers = {
  price: function (
    number: string | number,
    currency: string = "€",
    toFixed: number = 0
  ): string {
    let numberFloat = typeof number === "string" ? parseFloat(number) : number;
    if (!numberFloat) return "€ 0";

    return `${currency} ${numberFloat.toFixed(toFixed)}`;
  },
  priceWithoutVat: function (
    number: string | number,
    vat: number = 22,
    currency: string = "€",
    toFixed: number = 2
  ): string {
    let numberFloat = typeof number === "string" ? parseFloat(number) : number;
    if (!numberFloat) return `${currency} 0`;

    const totalWithoutVat = numberFloat / (1 + vat / 100);
    return `${currency} ${totalWithoutVat.toFixed(toFixed)}`;
  },
  shortName: function (user: iUser) {
    let shortName = "";
    if (user.firstName) {
      shortName = user.firstName;
    }
    if (user.secondName) {
      shortName += ` ${user.secondName[0]}.`;
    }

    return shortName;
  },
  takPeriodDates: function (startDate: string, endDate: string) {
    const from = new Date(startDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    const to = new Date(endDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
        month: "short",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    return `${from} - ${to}`;
  },
  feeToString: function (fee: number) {
    return `${Math.round(fee * 100)}%`;
  },
  getXDaysAgo: function (days: number) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  },
  hasRequiredProps: function (resourceObject: any, requiredProps: string[]) {
    // check if all required props are present in resource (es. collection)
    return requiredProps.every((detailProp) => {
      const props = detailProp.split(".");
      let obj = resourceObject;
      for (let i = 0; i < props.length; i++) {
        obj = obj[props[i] as any];
        if (obj === undefined) {
          console.log(
            `missing detail fro collection ${resourceObject.name}`,
            detailProp
            // resourceObject
          );
          return false;
        }
      }

      return true;
    });
  },
  getStringByValue: function (value?: string | number | Date) {
    if (!value) return "-";
    if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}.*$/.test(value)) {
      return datetimeToString(value);
    }
    return value.toString();
  },
};

export default helpers;
