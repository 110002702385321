import { useCallback, useEffect, useMemo, useState } from "react";
import { iCollection } from "@/services/collection";
import {
  getExport,
  getNftsOfCollectionPaginated,
  iNft,
  NFT_SELL_STATUS,
} from "@/services/nft";
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";

import { getColumns } from "./takslist-columns";
import { useModal } from "@/context/ModalContext";
import { datetimeToString, downloadExcel } from "@/utils/generic";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { DatePicker } from "@/components/form_fields/DatePicker";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

const exportModalSchema = z.object({
  date_start: z.date().optional(),
  date_end: z.date().optional(),
});

// TODO - extract this component to a separate file for reusing it from other places
function ExportModal({
  collection,
  onSuccess,
}: {
  collection: iCollection;
  onSuccess(): void;
}) {
  const [exportLoading, setExportLoading] = useState(false);
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof exportModalSchema>>({
    resolver: zodResolver(exportModalSchema),
    defaultValues: {
      date_start: undefined,
      date_end: undefined,
    },
  });

  const exportData = async (formData: z.infer<typeof exportModalSchema>) => {
    setExportLoading(true);

    try {
      // const start = new Date("01/01/2000").toISOString();
      // const end = new Date().toISOString();
      const start = formData.date_start
        ? formData.date_start.toISOString()
        : new Date("2000-01-01").toISOString();
      const end = formData.date_end
        ? formData.date_end.toISOString()
        : new Date().toISOString();

      const data = await getExport({
        collectionId: collection._id,
        start: start,
        end: end,
      });

      const formattedData = data.map((nft) => {
        return {
          SUBMIT_DATE: datetimeToString(
            nft.realCreationDate,
            undefined,
            undefined,
            true
          ),
          CREATION_DATE: datetimeToString(
            nft._createdAt,
            undefined,
            undefined,
            true
          ),
          BOOKING_NUMBER: nft.payload?.reference,
          OWNER: nft.owner,
          ROOMS: nft.payload?.rooms?.reduce(
            (a: any, b: any) => `${b.name} | ${a}`,
            ""
          ),
          CHECKIN: datetimeToString(nft.payload?.checkin),
          CHECKOUT: datetimeToString(nft.payload?.checkout),
          PRICE: Number(nft.originalPrice).toFixed(2),
          FEE: Number(
            (nft.originalPrice ?? 0) * (collection.feeOnCreation ?? 0)
          ).toFixed(2),
        };
      });

      downloadExcel(formattedData, `EXPORT ${datetimeToString(new Date())}`);

      onSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(exportData)}>
          <div className="space-y-3">
            <div className="flex gap-3">
              <FormField
                control={form.control}
                name="date_start"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("nft.export_date_start")}</FormLabel>
                    <FormControl>
                      <div>
                        <DatePicker {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="date_end"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("nft.export_date_end")}</FormLabel>
                    <FormControl>
                      <div>
                        <DatePicker {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-row-reverse gap-4">
              <LoadingSubmitButton variant={"takyon"} isLoading={exportLoading}>
                {t("collection.export_modal_title")}
              </LoadingSubmitButton>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}

export interface loadNftsProps {
  collection: iCollection;
  page?: number;
  size?: number;
  textQuery?: any;
  modeQuery?: any;
}

type QueryPayload = {
  $and: Array<any>;
};

interface Filters {
  status?: string;
}

export default function TaksDatatable({
  collection,
}: {
  collection: iCollection;
}) {
  const { collectionId } = useParams<{ collectionId: string }>();

  const [loading, setLoading] = useState(true);

  const { showModal, hideModal } = useModal();

  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState<Filters>({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iNft[]>([]);

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("collection.nfts")} - ${collection.name}`);
  }, [setTitle, collectionId]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      // gold are the bookings created directly by the hotel
      // let queryPayload: QueryPayload = {
      //   $and: [
      //     {
      //       $or: [
      //         { gold: { $exists: false } },
      //         { gold: false },
      //         { gold: null },
      //       ],
      //     },
      //   ],
      // };

      let queryPayload: any = {};

      if (searchTerm) {
        const searchConditions = [
          { _id: { $regex: searchTerm, $options: "i" } },
          { shortId: { $regex: searchTerm, $options: "i" } },
          { owner: { $regex: searchTerm, $options: "i" } },
          { "payload.reference": { $regex: searchTerm, $options: "i" } },
          { "payload.room": { $regex: searchTerm, $options: "i" } },
        ];
        if (!queryPayload.$and) {
          queryPayload.$and = [];
        }
        queryPayload.$and.push({ $or: searchConditions });
      }

      // filters
      // Aggiungi le condizioni dei filtri alla query
      Object.keys(filters).forEach((key) => {
        if (!queryPayload.$and) {
          queryPayload.$and = [];
        }
        if (key === "status") {
          const filterValue = filters[key] as string;
          if (filterValue === "active") {
            queryPayload.$and.push({
              "payload.checkout": { $gt: new Date() },
            });
            // original condition, if (isFilterActive) payload.query.accessStatus = NFT_ACCESS_STATUS.OPEN;
          } else if (filterValue === "sale") {
            queryPayload.$and.push({ sellStatus: NFT_SELL_STATUS.FOR_SALE });
            // original condition, if (isFilterSale) payload.query.sellStatus = NFT_SELL_STATUS.FOR_SALE;
          } else if (filterValue === "past") {
            queryPayload.$and.push({
              "payload.checkout": { $lt: new Date() },
            });
          } else {
            // remove $and conditions related to filters
            queryPayload.$and = queryPayload.$and.filter((condition: any) => {
              if (condition["payload.checkout"]) return false;
              if (condition["sellStatus"]) return false;
              return true;
            });
          }
        }
      });

      // console.log("queryPayload", queryPayload);

      const payload: any = {
        collectionId,
        page: page + 1,
        size: pageSize,
        query: {
          // ...filters,
          // ...sorting,
          ...queryPayload,
        },
      };

      const result = await getNftsOfCollectionPaginated(payload);

      const pageCount = Math.ceil(result.total / pageSize);

      setData(result.data);
      setPageCount(pageCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId, page, pageSize, filters, sorting, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData, collection]);

  const columns = useMemo(
    () => getColumns(collection, showModal, fetchData),
    [collection]
  );

  const toolbarFilters = [
    {
      type: "select-buttons",
      title: "Status",
      options: [
        { label: t("nft.active"), value: "active" },
        { label: t("nft.sale"), value: "sale" },
        { label: t("nft.past"), value: "past" },
        { label: t("nft.all"), value: "all" },
      ],
      key: "status",
      onChange: (value: string) => {
        // console.log("filters values", value);
        const activeFilters = { ...filters };
        activeFilters["status"] = value;
        setFilters(activeFilters);
      },
    },
  ];

  return (
    <div>
      <DataTableComponent
        data={data}
        columns={columns}
        pageCount={pageCount}
        searchPlaceholder={t("nft.fulltext_search")}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={(filters) => {
          console.log(
            "filters are handled with toolbarFilters object",
            filters
          );
        }}
        filters={toolbarFilters}
        actions={[
          <Button
            key="export-button"
            onClick={() =>
              showModal(
                <ExportModal onSuccess={hideModal} collection={collection} />,
                {
                  title: t("collection.export_modal_title"),
                  description: t("collection.export_modal_description"),
                }
              )
            }
            variant={"takyon"}
            className="gap-2"
          >
            <Download />
            {t("nft.export")}
          </Button>,
        ]}
      />
    </div>
  );
}
