import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";

import {
  configureStripeAccountCompany,
  fulfillPayments,
  getStripeAccountCompany,
  getPendingPayments,
  getPendingPaymentStats,
  getSubscriptionPortal,
  iPaymentMethod,
  iPendingPayment,
  getSubscriptionLink,
} from "@/services/payment";
import { selectUser } from "@/store/userSlice";
import { useModal } from "@/context/ModalContext";
import { datetimeToString } from "@/utils/generic";
import { H3 } from "../typography/Typography";
import { iStripeAccountData } from "@/services/stripe";
import PendingPaymentDatatable from "./PendingPaymentDatatable";
import { Badge } from "../ui/badge";
import { getStripeRedirectUrl } from "@/lib/links";
import { useLocation } from "react-router-dom";
import { PaymentBillingForm } from "@/pages/accountSettings/forms/PaymentBillingForm";

interface Props {
  payment: iPaymentMethod;
  collectionId?: string;
  canEditPayments?: boolean;
}

export function PaymentMethod({
  payment,
  collectionId,
  canEditPayments = true,
}: Props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [stats, setStats] = useState({
    totalAmount: 0,
    amountToFulfill: 0,
    subscriptionAmount: {
      subscriptionStripeAmount: 0,
      subscriptionTakyonAmount: 0,
    },
  });

  const { showModal } = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [payments, setPayments] = useState<iPendingPayment[]>([]);
  const [subPortalLink, setSubPortalLink] = useState("");
  const [account, setAccount] = useState<iStripeAccountData>();
  const { pathname } = useLocation();

  const [sessionLink, setSessionLink] = useState("");

  const getSessionLink = async () => {
    setIsLoading(true);
    try {
      const redirectForStripe = getStripeRedirectUrl(pathname, payment._id);
      const link = await getSubscriptionLink(payment._id, redirectForStripe);
      setSessionLink(link);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadSubPortalLink = async () => {
    setIsLoading(true);
    try {
      const link = await getSubscriptionPortal(collectionId ?? payment._id);
      setSubPortalLink(link);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const loadStats = async () => {
    setIsLoading(true);
    try {
      const data = await getPendingPaymentStats(payment._id, collectionId);
      setStats(data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const loadPendingPayments = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getPendingPayments(payment._id, page, collectionId);
      setPayments(data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const fulfillPaymentsClick = async () => {
    setIsLoading(true);
    try {
      await fulfillPayments({ paymentmethodId: payment._id });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    await loadPendingPayments();
    await loadStats();
  };

  const createAccount = async () => {
    setIsLoading(true);
    try {
      const link = await configureStripeAccountCompany({
        country: "it",
        paymentmethodId: payment._id,
      });
      window.location.href = link;
    } catch (error) {
      console.log(error);
    }
    // setIsLoading(false);
  };

  const loadStripeAccount = async () => {
    if (!payment.stripeId) return;

    setIsLoading(true);
    try {
      const account = await getStripeAccountCompany({
        paymentmethodId: payment._id,
      });
      setAccount(account);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const isAdmin = user?.isAdmin;

  useEffect(() => {
    loadStats();
    if (payment.customerId) loadSubPortalLink();
    else getSessionLink();
    loadStripeAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadPendingPayments(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="space-y-4">
      {payment && (
        <div>
          <Button
            variant={"takyon"}
            size={"sm"}
            onClick={() =>
              showModal(
                <PaymentBillingForm
                  billing={payment?.billingDetails}
                  readonly={!canEditPayments}
                />,
                {
                  title: t("payment.title"),
                  description: "",
                }
              )
            }
            className="cursor-pointer selected"
          >
            {String(t("payment.open_billing"))}
          </Button>
        </div>
      )}
      <div className="flex gap-3">
        <div className="flex-1 border border-gray-200 p-4 rounded-lg flex flex-col justify-between">
          <div>
            <H3 className="font-bold mb-4">{t("payment.partner_to_takyon")}</H3>
            <div>
              <strong>{t("payment.subscription_status")}:</strong>{" "}
              <Badge
                variant={
                  payment.subscriptionStatus === "active" ||
                  payment.subscriptionStatus === "trialing"
                    ? "takyon"
                    : payment.subscriptionStatus === "past_due"
                    ? "outline"
                    : "outline"
                }
                className={
                  payment.subscriptionStatus &&
                  payment.subscriptionStatus !== "active" &&
                  payment.subscriptionStatus !== "trialing"
                    ? "bg-yellow-500"
                    : ""
                }
              >
                {payment.subscriptionStatus ?? t("payment.needs_setup")}
              </Badge>
            </div>
            {payment.subscriptionStatus &&
              payment.subscriptionStatus !== "active" &&
              payment.subscriptionStatus !== "trialing" && (
                <p>
                  <span className="font-semibold">
                    {t("payment.subscription_next_invoice")}:{" "}
                  </span>
                  {datetimeToString(payment.subscriptionCurrentEndDate)}
                </p>
              )}
            {payment.subscriptionCredit ? (
              <p>
                <span className="font-semibold">
                  {t("payment.subscription_credit")}:{" "}
                </span>
                {payment.subscriptionCredit.toFixed(2)} €
              </p>
            ) : null}
            {isAdmin && (
              <>
                {/* <p>
              <span className="font-semibold">
                {t("payment.subscription_amount_takyon")}:{" "}
              </span>
              {stats.subscriptionAmount.subscriptionTakyonAmount.toFixed(2)} €
            </p> */}
              </>
            )}
            <p>
              <span className="font-semibold">
                {t("payment.subscription_amount_stripe")}:{" "}
              </span>
              {stats.subscriptionAmount.subscriptionStripeAmount.toFixed(2)} €
            </p>
          </div>
          <div className="mt-6 flex justify-end">
            {payment.customerId ? (
              <Button
                variant={"gray"}
                onClick={() => window.open(subPortalLink, "_blank")}
                disabled={!subPortalLink}
              >
                {t("payment.subscription_portal")}
              </Button>
            ) : (
              <Button
                onClick={() => window.open(sessionLink, "_blank")}
                disabled={!sessionLink}
              >
                {t("payment.setup_subscription")}
              </Button>
            )}
          </div>
        </div>

        {/* <div className="flex-1 border border-gray-200 p-4 rounded-lg flex flex-col justify-between">
          <div>
            <H3 className="font-bold mb-4">
              {t("payment.takyon_to_partner_supply")}
            </H3>
            <p>
              <strong>{t("payment.subscription_status")}:</strong>{" "}
              {payment.stripeChargesEnabled && payment.stripeDetailsSubmitted
                ? "🟢"
                : payment.subscriptionStatus === "past_due"
                ? "🟡"
                : t("payment.needs_setup")}
            </p>

            <p>
              <span className="font-semibold">{t("payment.balance")}: </span>
              {account?.balance ?? "-"} €
            </p>
          </div>

          <div className="flex justify-end">
            <Button
              variant={"gray"}
              disabled={isLoading}
              onClick={createAccount}
            >
              {t("payment.setup_account")}
            </Button>
          </div>
        </div> */}
      </div>

      <Separator className="mt-8 mb-8" />
      <div className="flex-1 border border-gray-200 p-4 rounded-lg flex flex-col justify-between">
        <div>
          <H3 className="font-bold mb-4">{t("payment.takyon_to_partner")}</H3>
          <p>
            <span className="font-semibold">{t("payment.total_amount")}: </span>
            {stats.totalAmount.toFixed(2)} €
          </p>
          <p>
            <span className="font-semibold">
              {t("payment.amount_to_fulfill")}:{" "}
            </span>
            {stats.amountToFulfill.toFixed(2)} €
          </p>
        </div>
        {isAdmin && (
          <div className="flex justify-end">
            <Button
              variant={"takyon"}
              className="mt-4"
              onClick={fulfillPaymentsClick}
            >
              {t("payment.fulfill_all")}
            </Button>
          </div>
        )}
        <Separator className="my-5" />
        <div className="pb-4">
          <PendingPaymentDatatable paymentMethodId={payment._id} />
        </div>
      </div>

      <div className="mb-8"></div>
    </div>
  );
}
