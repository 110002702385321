import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { iCollection, patchCollection } from "@/services/collection";
import { iGroup } from "@/services/groups";
import { LargeText } from "../typography/Typography";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import iDataAnalysisMask, {
  getAnalysisMaskPaginate,
} from "@/services/dataanalysismask";
import { Button } from "../ui/button";
import { BookingEngine, bookingUrlParser, InternalParam } from "@/utils/common";

const formSchema = z.object({
  dataAnalysisMaskId: z.string(),
  bookingEngineUrlRegex: z.string().optional(),
  conversionRate: z.number().optional(),
  otaFee: z.number().optional(),
  bestRateOnOtaType: z.enum(["refundable", "non-refundable"]).optional(),
  bookingComId: z.string().optional(),
  bookingEngine: z.string(),
  bookingEngineParams: z
    .array(
      z.object({
        param: z.string(),
        internal: z.nativeEnum(InternalParam),
        match: z.string().optional(),
      })
    )
    .optional(),
  deltaPriceBookingCom: z.number().optional(),
  deltaPriceHotelsCom: z.number().optional(),
  advantagesOnDirectWebsite: z.string().optional(),
});

export default function FormSettingsTrialPeriod({
  collection,
  group,
  onRefresh,
}: {
  collection: iCollection;
  group: iGroup;
  onRefresh(): void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dataAnalysisMaskId: collection.dataAnalysisMaskId,
      bookingEngineUrlRegex: collection.preData?.bookingEngineUrlRegex || "",
      conversionRate: collection.preData?.conversionRate || undefined,
      otaFee: collection.preData?.otaFee || undefined,
      bestRateOnOtaType: collection.preData?.bestRateOnOtaType || undefined,
      bookingComId: collection.preData?.bookingComId || "",
      bookingEngine: collection.preData?.bookingEngine || "",
      bookingEngineParams: collection.preData?.bookingEngineParams
        ? Object.entries(collection.preData.bookingEngineParams).map(
            ([k, v]) => ({ param: k, internal: k as InternalParam, match: v })
          )
        : undefined,
      deltaPriceBookingCom:
        collection.preData?.deltaPriceBookingCom || undefined,
      deltaPriceHotelsCom: collection.preData?.deltaPriceHotelsCom || undefined,
      advantagesOnDirectWebsite: collection.preData?.advantagesOnDirectWebsite
        ? collection.preData?.advantagesOnDirectWebsite.join(",")
        : undefined,
    },
  });

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [masks, setMasks] = useState<iDataAnalysisMask[]>([]);
  const [engineNotRecognized, setEngineNotRecognized] = useState(false);

  const load = async () => {
    try {
      const data = await getAnalysisMaskPaginate({ page: 1, size: 500 });
      console.log("getAnalysisMaskPaginate", data);
      setMasks(data.data);
    } catch (error) {
      console.log("error fetching masks", error);
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);

    try {
      await patchCollection({
        collectionId: collection._id,
        dataAnalysisMaskId: data.dataAnalysisMaskId,
        preData: {
          bookingEngineUrlRegex: data.bookingEngineUrlRegex,
          conversionRate: data.conversionRate,
          otaFee: data.otaFee,
          bestRateOnOtaType: data.bestRateOnOtaType,
          bookingComId: data.bookingComId,
          bookingEngine: data.bookingEngine,
          bookingEngineParams: Object.fromEntries(
            data.bookingEngineParams?.map((p) => [p.internal, p.match]) || []
          ),
          deltaPriceBookingCom: data.deltaPriceBookingCom,
          deltaPriceHotelsCom: data.deltaPriceHotelsCom,
          advantagesOnDirectWebsite: data.advantagesOnDirectWebsite
            ? data.advantagesOnDirectWebsite.split(",")
            : [],
        },
      });

      onRefresh();
    } catch (error) {
      console.error(error);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    load();
  }, []);

  const bookingEngineParams = useWatch({
    name: "bookingEngineParams",
    control: form.control,
  });

  return (
    <Form {...form}>
      <form
        id="FormSettingsAnalysisMask"
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div>
          <LargeText>{t("analytics.settings.title")}</LargeText>
          <p className="text-sm text-muted-foreground">
            {t("analytics.settings.description")}
          </p>
        </div>
        <div className="space-y-3">
          <FormField
            control={form.control}
            name="dataAnalysisMaskId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.dataAnalysisMaskId")} *
                </FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t(
                          "analytics.settings.dataAnalysisMaskId_placeholder"
                        )}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {masks.map((value, index) => (
                      <SelectItem
                        key={"item" + index}
                        value={value._id ?? t("form.select")}
                      >
                        {value.name}
                      </SelectItem>
                    ))}
                    <SelectSeparator />
                    <Button
                      className="w-full px-2"
                      variant="ghost"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        field.onChange("");
                      }}
                    >
                      Clear
                    </Button>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="bookingEngineUrlRegex"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.bookingEngineUrlRegex")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t(
                      "analytics.settings.bookingEngineUrlRegex_placeholder"
                    )}
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.bookingEngineUrlRegex_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="conversionRate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("analytics.settings.conversionRate")}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.001}
                    placeholder={t(
                      "analytics.settings.conversionRate_placeholder"
                    )}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    value={field.value}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.conversionRate_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="otaFee"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("analytics.settings.otaFee")}</FormLabel>
                <FormControl>
                  <Input
                    step={0.01}
                    type="number"
                    placeholder={t("analytics.settings.otaFee_placeholder")}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    //value={field.value}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.otaFee_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="bestRateOnOtaType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.bestRateOnOtaType")}
                </FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t(
                          "analytics.settings.bestRateOnOtaType_placeholder"
                        )}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="refundable">
                        {t("analytics.settings.refundable")}
                      </SelectItem>
                      <SelectItem value="non-refundable">
                        {t("analytics.settings.non_refundable")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.bestRateOnOtaType_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="bookingComId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("analytics.settings.bookingComId")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t(
                      "analytics.settings.bookingComId_placeholder"
                    )}
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.bookingComId_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="deltaPriceBookingCom"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.deltaPriceBookingCom")}
                </FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t(
                      "analytics.settings.deltaPriceBookingCom_placeholder"
                    )}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.deltaPriceBookingCom_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="deltaPriceHotelsCom"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.deltaPriceHotelsCom")}
                </FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step={0.01}
                    placeholder={t(
                      "analytics.settings.deltaPriceHotelsCom_placeholder"
                    )}
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormDescription>
                  {t("analytics.settings.deltaPriceHotelsCom_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="advantagesOnDirectWebsite"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("analytics.settings.advantagesOnDirectWebsite")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t(
                      "analytics.settings.advantagesOnDirectWebsite"
                    )}
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t(
                    "analytics.settings.advantagesOnDirectWebsite_description"
                  )}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <SelectSeparator />

          <div>
            <LargeText>
              {t("analytics.settings.bookingEngineRedirect_title")}
            </LargeText>
            <p className="text-sm text-muted-foreground">
              {t("analytics.settings.bookingEngineRedirect_description")}
            </p>
          </div>

          <FormItem>
            <FormLabel> {t("analytics.settings.bookingEngineUrl")}</FormLabel>
            <Input
              placeholder="https://mybookingengine.com/search?hotel=123&arrival=2025-01-10"
              onChange={(e) => {
                if (e.target.value === "") return;

                const data = bookingUrlParser(e.target.value);
                if (!data.engine) return setEngineNotRecognized(true);

                setEngineNotRecognized(false);
                form.setValue("bookingEngine", data.engine);
                form.setValue("bookingEngineParams", data.params);
              }}
            />
            <FormDescription>
              {t("analytics.settings.bookingEngineUrl_description")}
            </FormDescription>
            <FormMessage>
              {engineNotRecognized
                ? t("analytics.settings.bookingEngineNotRecognized")
                : ""}
            </FormMessage>
          </FormItem>

          <FormField
            control={form.control}
            name="bookingEngine"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("partners.bookingEngine")} {t("form.required")}
                </FormLabel>
                <Select
                  {...field}
                  onValueChange={(v) => {
                    setEngineNotRecognized(false);
                    field.onChange(v);
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.values(BookingEngine)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((e, index) => (
                        <SelectItem
                          key={"item" + index}
                          value={e ?? t("form.select")}
                        >
                          {e}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          {!!bookingEngineParams?.length && (
            <div
              className={`grid grid-cols-${bookingEngineParams.length} gap-2`}
            >
              {bookingEngineParams.map((p, i) => (
                <FormField
                  control={form.control}
                  name={`bookingEngineParams.${i}.match`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {form.getValues(`bookingEngineParams.${i}.param`)}{" "}
                        {t("form.required")}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-row gap-4">
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isSubmitting}
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
