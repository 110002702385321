import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { CalendarDays } from "lucide-react";
import { useAuthContext } from "@/context/AuthContext";

export default function SendEmailToExpert() {
  const { t } = useTranslation();
  const { userEmail } = useAuthContext();

  const onClick = () => {
    const subject = encodeURIComponent(
      t("mailto.send_email_to_expert.subject", {
        userEmail: userEmail,
      })
    );
    const body = encodeURIComponent(
      `*** ${t("mailto.send_email_to_expert.body")} ***`
    );

    window.location.href = `mailto:partner.support@takyon.io?subject=${subject}&body=${body}`;
  };

  return (
    <Button variant="outline" className="w-full mt-3" onClick={onClick}>
      <CalendarDays className="mr-2 h-4 w-4" />
      {t("analytics.contact.support")}
    </Button>
  );
}
