import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { role } from "@/services/member";
import { useTranslation } from "react-i18next";
import { Badge } from "../ui/badge";
import { useRbac } from "@/hooks/rbac";

export default function RoleDropdown({
  roles,
  onSelect,
  defaultValue,
  disabled,
}: {
  roles: role<any>[];
  onSelect(role: role<any>): void;
  defaultValue?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { isSuperAdmin } = useRbac();

  return defaultValue === "admin" ? (
    <Badge variant="secondary">{t("role.admin")}</Badge>
  ) : (
    <Select
      disabled={disabled}
      value={defaultValue}
      onValueChange={(e) => {
        onSelect(roles.find((role) => role.name === e)!);
      }}
    >
      <SelectTrigger className="w-full text-left">
        <SelectValue>{t(`role.${defaultValue}`)}</SelectValue>
      </SelectTrigger>
      <SelectContent
        position="popper"
        className="max-h-[300px]"
        onCloseAutoFocus={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <SelectGroup>
          <SelectLabel>{t("team.roles")}</SelectLabel>

          {roles.map((role, index) => {
            //console.log(role);
            if (isSuperAdmin === false && role.onlyAdmin) return null;
            return (
              <SelectItem
                style={{ maxWidth: "450px" }}
                key={"role" + index}
                value={role.name}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-left">{t(`role.${role.name}`)}</p>
                  {role.name !== "stealth_events" &&
                    role.name !== "stealth_editor" && (
                      <p className="text-left text-xs text-gray-400">
                        {t("collection.role_description_" + role.name)}
                      </p>
                    )}
                </div>
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
